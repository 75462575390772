import { Link } from "react-router-dom";
import Banner from "components/Banner";
import Template from "./Template";
import GoRequest from "components/GoRequest";
import Review from "components/Review";
import Img from "components/Img";

export default ({ title, className = "" }) => {
  return (
    <Template title={title} className={className}>
      <Banner
        banner={require("assets/images/about-banner.jpg")}
        className="homeBanner aboutBanner"
        align="start"
        title={
          <>
            <span className="blueClr"> “Cash flow & Real Estate</span> changed
            our lives from the start. We just didn’t realize it until a decade
            later.”
          </>
        }
      >
        <p
          className="animated"
          data-emergence="visible"
          data-wow-duration="2s"
          data-wow-delay="0.4s"
        >
          <span>{`— S&S aka Sabrina & Sief`} </span>
        </p>
      </Banner>
      <>
        {/* Team End */}
        <section className="reviewSection aboutReview">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-sm-6 col-md-6 col-lg-3 textColumn">
                <div className="reviewSection__text ">
                  <h6 className="blueClr m-0">Our Team Comes From:</h6>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-9 sliderColumn">
                <div className="reviewSection__clients client-slider">
                  <div className="client-slider--item">
                    <Img src={require("assets/images/svg/apple-shape.svg")} />
                  </div>
                  <div className="client-slider--item">
                    <Img
                      src={require("assets/images/svg/vacasa-llc-vector-logo.svg")}
                    />
                  </div>
                  <div className="client-slider--item">
                    <Img
                      src={require("assets/images/svg/facebook-svgrepo-com.svg")}
                    />
                  </div>
                  <div className="client-slider--item">
                    <Img
                      src={require("assets/images/svg/D._R._Horton_logo.svg")}
                    />
                  </div>
                  <div className="client-slider--item">
                    <Img src={require("assets/images/svg/uber-logo.svg")} />
                  </div>
                  <div className="client-slider--item">
                    <Img src={require("assets/images/svg/tesla-logo.svg")} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ourTeam dottedSectionBg">
          <div className="container">
            <div className="row">
              <div
                className="ourTeam__head animated fdeIn__Up"
                data-emergence="visible"
              >
                <h2 className="commonHeading">Our Team</h2>
              </div>
              <div className="ourteam__subHead">
                <h3 className="medium">Managing Partners</h3>
              </div>
            </div>
          </div>
          {/* partner */}
          <div className="ourTeam__partner">
            <div className="container">
              <div className="ourTeam__inner">
                <div className="row ourTeam__hldr">
                  <div className="ourTeam__item">
                    <div
                      className="ourTeam__list animated fdeIn__Up"
                      data-emergence="visible"
                    >
                      <div className="ourTeam__imghldr commonBoxshadow">
                        <Img src={require("assets/images/sief.jpg")} />
                      </div>
                      <div className="ourTeam__content">
                        <span className="ourTeam__name">Sief</span>
                        <span className="ourTeam__pos">General Partner</span>
                        <span className="ourTeam__link">
                          <a
                            href="https://www.linkedin.com/in/siefkhafagi/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            LinkedIn
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="ourTeam__item">
                    <div
                      className="ourTeam__list animated fdeIn__Up"
                      data-emergence="visible"
                    >
                      <div className="ourTeam__imghldr commonBoxshadow">
                        <Img src={require("assets/images/Sabrina_2022.jpg")} />
                      </div>
                      <div className="ourTeam__content">
                        <span className="ourTeam__name">Sabrina</span>
                        <span className="ourTeam__pos">General Partner</span>
                        <span className="ourTeam__link">
                          <a
                            href="https://www.linkedin.com/in/sabrinaguler/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            LinkedIn
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="ourTeam__item">
                    <div
                      className="ourTeam__list animated fdeIn__Up"
                      data-emergence="visible"
                    >
                      <div className="ourTeam__imghldr commonBoxshadow">
                        <Img src={require("assets/images/sam.jpg")} />
                      </div>
                      <div className="ourTeam__content">
                        <span className="ourTeam__name">Sam</span>
                        <span className="ourTeam__pos">General Partner</span>
                        <span className="ourTeam__link">
                          <a
                            href="https://www.linkedin.com/in/samuelsilverman3/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            LinkedIn
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Leadership */}
          <div className="ourTeam__leadership">
            <div className="container">
              <div className="row">
                <div className="ourteam__subHead">
                  <h3 className="medium">Leadership</h3>
                </div>
              </div>
              <div className="ourTeam__inner">
                <div className="row ourTeam__hldr">
                  <div className="ourTeam__item">
                    <div
                      className="ourTeam__list animated fdeIn__Up"
                      data-emergence="visible"
                    >
                      <div className="ourTeam__imghldr commonBoxshadow">
                        <Img src={require("assets/images/mick.jpg")} />
                      </div>
                      <div className="ourTeam__content">
                        <span className="ourTeam__name">Mick</span>
                        <span className="ourTeam__pos">
                          Head of Asset Management
                        </span>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="ourTeam__item">
                    <div
                      className="ourTeam__list animated fdeIn__Up"
                      data-emergence="visible"
                    >
                      <div className="ourTeam__imghldr commonBoxshadow">
                        <Img src={require("assets/images/corbin.jpg")} />
                      </div>
                      <div className="ourTeam__content">
                        <span className="ourTeam__name">Corbin</span>
                        <span className="ourTeam__pos">Head of Finance</span>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="ourTeam__item">
                    <div
                      className="ourTeam__list animated fdeIn__Up"
                      data-emergence="visible"
                    >
                      <div className="ourTeam__imghldr commonBoxshadow">
                        <Img src={require("assets/images/austin.jpg")} />
                      </div>
                      <div className="ourTeam__content">
                        <span className="ourTeam__name">Austin</span>
                        <span className="ourTeam__pos">Head of Revenue</span>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="ourTeam__item">
                    <div
                      className="ourTeam__list animated fdeIn__Up"
                      data-emergence="visible"
                    >
                      <div className="ourTeam__imghldr commonBoxshadow">
                        <Img src={require("assets/images/josh.jpg")} />
                      </div>
                      <div className="ourTeam__content">
                        <span className="ourTeam__name">Josh</span>
                        <span className="ourTeam__pos">Head of Ops</span>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="ourTeam__item">
                    <div
                      className="ourTeam__list animated fdeIn__Up"
                      data-emergence="visible"
                    >
                      <div className="ourTeam__imghldr commonBoxshadow">
                        <Img src={require("assets/images/john.jpg")} />
                      </div>
                      <div className="ourTeam__content">
                        <span className="ourTeam__name">John</span>
                        <span className="ourTeam__pos">Head of Data</span>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="ourTeam__item">
                    <div
                      className="ourTeam__list animated fdeIn__Up"
                      data-emergence="visible"
                    >
                      <div className="ourTeam__imghldr commonBoxshadow">
                        <Img src={require("assets/images/taylor.jpg")} />
                      </div>
                      <div className="ourTeam__content">
                        <span className="ourTeam__name">Taylor</span>
                        <span className="ourTeam__pos">Head of ACQ</span>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                </div>
              </div>
            </div>
          </div>
          {/* Support */}
          <div className="ourTeam__support">
            <div className="container">
              <div className="row">
                <div className="ourteam__subHead">
                  <h3 className="medium">Support</h3>
                </div>
              </div>
              <div className="ourTeam__inner">
                <div className="row ourTeam__hldr">
                  <div className="ourTeam__item">
                    <div
                      className="ourTeam__list animated fdeIn__Up"
                      data-emergence="visible"
                    >
                      <div className="ourTeam__imghldr commonBoxshadow">
                        <Img src={require("assets/images/demmi.jpg")} />
                      </div>
                      <div className="ourTeam__content">
                        <span className="ourTeam__name">Demi</span>
                        <span className="ourTeam__pos">Accounting</span>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="ourTeam__item">
                    <div
                      className="ourTeam__list animated fdeIn__Up"
                      data-emergence="visible"
                    >
                      <div className="ourTeam__imghldr commonBoxshadow">
                        <Img src={require("assets/images/joe.jpg")} />
                      </div>
                      <div className="ourTeam__content">
                        <span className="ourTeam__name">Joe</span>
                        <span className="ourTeam__pos">
                          Transaction <br /> Coordinator{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="ourTeam__item">
                    <div
                      className="ourTeam__list animated fdeIn__Up"
                      data-emergence="visible"
                    >
                      <div className="ourTeam__imghldr commonBoxshadow">
                        <Img src={require("assets/images/parker.jpg")} />
                      </div>
                      <div className="ourTeam__content">
                        <span className="ourTeam__name">Alerie</span>
                        <span className="ourTeam__pos">Assistant</span>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="ourTeam__item">
                    <div
                      className="ourTeam__list animated fdeIn__Up"
                      data-emergence="visible"
                    >
                      <div className="ourTeam__imghldr commonBoxshadow">
                        <Img src={require("assets/images/emma.jpg")} />
                      </div>
                      <div className="ourTeam__content">
                        <span className="ourTeam__name">Emma</span>
                        <span className="ourTeam__pos">Assistant</span>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  {/*  */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Team Review End */}
        <section className="ourStory">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="ourStory__lft animated fdeIn__Up"
                  data-emergence="visible"
                >
                  <h3>Our Story</h3>
                </div>
              </div>
              <div className="col-lg-8">
                <div
                  className="ourStory__ryt animated fdeIn__Up"
                  data-emergence="visible"
                >
                  <p>
                    We’ve been investing in real estate since 2009. Doing
                    everything from private lending, flipping and long term
                    hold. The journey to cash flow and growth was hard.
                    Incredibly time consuming, lots of mistakes and over a
                    decade of memories, both good and bad. Around that same
                    time, a small little company was beginning that had a wild
                    vision of allowing a “stranger” to stay the night at your
                    place. That company was Airbnb. Little did we know it but
                    our journey would lead us to STRs (short term rentals) as
                    one of the biggest reasons we were able to break free from
                    the 9-5 from places like Facebook & Apple.
                  </p>
                  <p>
                    10 years later and we’ve learned that having short term
                    rentals in your portfolio for the cash flow can be a
                    powerful wealth building strategy. The process of doing one
                    was shitty. Lenders. Brokers. Management. All headaches.{" "}
                  </p>
                  <p>
                    So, we built a better mouse trap. One that was simple,
                    passive and partnership driven with aligned incentives from
                    day one. Cash flow from day one. Transparency from day one.
                    And tax benefits from day one.
                  </p>
                  <p>
                    We’re focusing helping others accomplish what we’ve been
                    able to do and to date, we’ve helped hundreds get started or
                    continue their REI journey.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Our Story End */}
        <section className="ourVision">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="ourVision__head animated fdeIn__Up"
                  data-emergence="visible"
                >
                  <h3>Our Vision</h3>
                </div>
              </div>
            </div>
            <div
              className="row ourVision__hldr animated fdeIn__Up"
              data-emergence="visible"
            >
              <div className=" col-lg-4 ourVision__crd ourVision__crd--hover">
                <div className="blackCard  blackCard--hover blackBg commonBoxshadow">
                  <div className="blackCard__inner d-flex flex-column">
                    <div className="blackCard__img">
                      <Img
                        src={require("assets/images/svg/T-blue-small.svg")}
                      />
                    </div>
                    <div className="blackCard__hdng">
                      <h3 className="blueClr">
                        “Freedom” – Whatever that means to you.
                      </h3>
                    </div>
                    <div className="blackCard__cntnt">
                      <p>
                        Maybe it’s to break away from the 9-5. Maybe it’s to
                        save for college. Maybe it’s to take care of our
                        parents. Whatever your “why” is, cash flow and real
                        estate can help.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className=" col-lg-4 ourVision__crd ">
                <div className="blackCard  blackCard--hover blackBg commonBoxshadow">
                  <div className="blackCard__inner d-flex flex-column">
                    <div className="blackCard__img">
                      <Img
                        src={require("assets/images/svg/T-blue-small.svg")}
                      />
                    </div>
                    <div className="blackCard__hdng">
                      <h3 className="blueClr">Aligned Incentives.</h3>
                    </div>
                    <div className="blackCard__cntnt">
                      <p>
                        No one likes un-necessary fees. Neither do we. You get
                        paid first and foremost. Our partnership is built so
                        that we’ll always have each others best interests.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className=" col-lg-4 ourVision__crd ourVision__crd--hover">
                <div className="blackCard blackBg blackCard--hover commonBoxshadow">
                  <div className="blackCard__inner d-flex flex-column">
                    <div className="blackCard__img">
                      <Img
                        src={require("assets/images/svg/T-blue-small.svg")}
                      />
                    </div>
                    <div className="blackCard__hdng">
                      <h3 className="blueClr">
                        Passive. <br /> So you can <br />
                        live your life.{" "}
                      </h3>
                    </div>
                    <div className="blackCard__cntnt">
                      <p>
                        Family. Work. Hobbies. Adventures. Life. We don’t have
                        enough time or energy every day as it is. Investments
                        are intended to work for you. We can help.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Our Vision Ends */}
        <section className="parents">
          <div className="container">
            <div className="row parents__inner ">
              <div className="col-lg-5 col-md-12">
                <div
                  className="parents__lft animated fdeIn__Up"
                  data-emergence="visible"
                >
                  <h3>
                    Parents, dog moms, techies and more. REI + Cash Flow is a
                    game changer.{" "}
                  </h3>
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div
                  className="parents__ryt animated fdeIn__Up"
                  data-emergence="visible"
                >
                  <p>
                    No matter who you are, what you do or where you live, REI
                    doesn’t have to feel impossible. Our community comes from
                    all walks of life, from all around the world with one
                    singular goal: to invest for their “why” and each person has
                    a story.
                  </p>
                  <div className="parents__rytbtm">
                    <span> Let’s write yours. </span>
                    <span>Sabrina & Sief</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <Review />
      <GoRequest img={require("assets/images/about-btm-img.jpg")} />
    </Template>
  );
};
