import { Link } from "react-router-dom";
import Banner from "components/Banner";
import Template from "./Template";
import Review from "components/Review";
import { getCalendlyUrl } from "utils/calendly";
import { useEffect } from "react";
import { registerUser } from "utils/api";

export default ({ title, className = "" }) => {
  useEffect(() => {
    registerUser();
  }, []);

  return (
    <Template title={title} className={className}>
      <div style={{ width: "100vw", height: "100vh" }}>
        <iframe width="100%" height="100%" src={getCalendlyUrl()} style={{ border: "none", flex: 2 }} />
      </div>
    </Template>
  );
};
