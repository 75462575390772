import { useEffect, useMemo } from "react";

export default ({ src, preload = true, ...other }) => {
  useEffect(() => {}, []);
  const showPreload = useMemo(() => {
    return src && src.indexOf && src.indexOf(".webp") >= 0 && preload;
  }, [src, preload]);
  const parsedSrc = useMemo(() => {
    if (typeof src == "string") {
      return src;
    }
    if (typeof src == "object" && src[0]) {
      return src[0];
    }
    return src ? src.default || "" : "";
  }, [src]);
  return (
    <>
      {!showPreload && <img src={parsedSrc} {...other} />}
      {showPreload && (
        <link
          rel="preload"
          fetchpriority="high"
          as="image"
          href={parsedSrc}
          type="image/webp"
        ></link>
      )}
    </>
  );
};
