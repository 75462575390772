import { Link } from "react-router-dom";
import Banner from "components/Banner";
import Template from "./Template";
import GoRequest from "components/GoRequest";
import Review from "components/Review";
import Img from "components/Img";

export default ({ title, className = "" }) => {
  return (
    <Template title={title} className={className}>
      <Banner
        className="contactBanner"
        banner={require("assets/images/contact-bnr.jpg")}
        title={
          <>
            <span className="blueClr ">Get in Touch</span>
          </>
        }
      >
        <div className="bannerSection__leftmdm">
          <h3 className="medium">How can we help?</h3>
        </div>
        <div className="contactBanner__txt">
          <p>
            If you’re an interested investor, please fill out our
            <Link to="/request-invite" className="bold blueClr">
              <u> Request Invite form.</u>
            </Link>
          </p>
          <p>
            If you’re a vendor, lender or just have a curious question, please
            reach out to us via form on this page.
          </p>
          <p>
            <span className="bold blueClr">Call or Text Us: </span>
            <Link to="tel:4156504705">(415) 650-4705</Link>
          </p>
        </div>
      </Banner>
      <>
        <section className="contactForm dottedSectionBg">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 col-md-12">
                <div className="contactForm__hldr">
                  <form action="">
                    <div className="contactForm__groupInput">
                      <input type="text" placeholder="Name*" required />
                    </div>
                    <div className="contactForm__groupInput">
                      <input
                        type="email"
                        placeholder="Email Address*"
                        required
                      />
                    </div>
                    <div className="contactForm__groupInput">
                      <input type="text" placeholder="Subject*" required />
                    </div>
                    <div className="contactForm__grouptextarea">
                      <textarea
                        name=""
                        id=""
                        cols="20"
                        rows="7"
                        placeholder="Message*"
                        required
                      ></textarea>
                    </div>
                    <button
                      type="submit"
                      className="commonBtn blueBg requestBtn d-flex align-items-center cd-popup-trigger justify-content-center"
                    >
                      <span>SUBMIT</span>
                      <Img src={require("assets/images/svg/arrow.svg")} />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <Review />
      <GoRequest img={require(`assets/images/contact__btmbnr.jpg`)} />
      <div class="cd-popup " role="alert">
        <div class="cd-popup-container commonBoxshadow">
          <div class="cd-popup__content text-center ">
            <div class="cd-popup__contentImg">
              <Img src={require("assets/images/popup-t-logo.svg")} />
            </div>
            <div class="cd-popup__contenttxt">
              <h3 class="bold ">Confirmed</h3>
              <p>Thank you for contacting us</p>
            </div>
            <div class="cd-popup__contentbtn">
              <a class="commonBtn blueBg" href="contact-us">
                Continue
              </a>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};
