import { Link } from "react-router-dom";
import Banner from "components/Banner";
import Template from "./Template";
import GoRequest from "components/GoRequest";
import { useLayoutEffect } from "react";
import Review from "components/Review";
import Img from "components/Img";

export default ({ title, className = "" }) => {
  useLayoutEffect(() => {
    if (window.yearSlider) {
      window.yearSlider();
    }
  }, []);

  return (
    <Template title={title} className={className}>
      <Banner
        className="datapageBanner"
        banner={require("assets/images/data-banner.jpg")}
        title={<>Peep the data</>}
      >
        <Link
          to="/request-invite"
          className="commonBtn blackBg d-flex align-items-center justify-content-center"
        >
          <span>Request invite</span>
          <Img src={require("assets/images/svg/arrow.svg")} />
        </Link>
        <div className="dataArea">
          <div className="dataArea__box">
            <div className="dataCount textGradient">
              $ <span className="counter" data-to="25000"></span>+{" "}
            </div>
            <div className="dataCntnt">
              <p>
                in annual wealth <br /> creation per property, <br /> per year{" "}
              </p>
            </div>
          </div>
          <div className="dataArea__box boxPosition">
            <div className="dataCount textGradient">
              <span className="counter" data-to="30"></span>%
            </div>
            <div className="dataCntnt">
              <p>
                lower taxes paid by <br /> owning direct real estate{" "}
              </p>
            </div>
          </div>
        </div>
      </Banner>
      <>
        <section className="dataSection">
          <div className="container">
            <div className="dataSection__cntnt d-flex align-items-end justify-content-between">
              <div className="dataSection__cntnt--lft">
                <h1
                  className="commonHeading blackClr animated fdeIn__Up"
                  data-emergence="visible"
                >
                  The Smartest Thing You Can Do.
                </h1>
                <p>
                  Real Estate is a tremendous vehicle to not only pay for daily
                  expenses via cash flow but also build equity and have someone
                  else pay debts down for you. Not to mention tax benefits and
                  other reasons to invest in STRs. The data tells the story.
                </p>
              </div>
              <div className="dataSection__cntnt--rgt">
                <ul className="list-style-none">
                  <li>
                    <h3 className="blueClr">Appreciation</h3>
                  </li>
                  <li>
                    <h3 className="blueClr">Debt Pay Down</h3>
                  </li>
                  <li>
                    <h3 className="blueClr">Cash Flow</h3>
                  </li>
                  <li>
                    <h3 className="blueClr">Tax Benefits</h3>
                  </li>
                </ul>
              </div>
            </div>
            <div className="dataSection__wrapper">
              <div className="dataSection__row">
                <div className="dataSection__column text-center">
                  <div className="dataCount textGradient">
                    $ <span className="counter" data-to="67"></span>K{" "}
                  </div>
                  <div className="dataCntnt">
                    <p>
                      Our avg revenue <br /> per asset{" "}
                    </p>
                  </div>
                </div>
                <div className="dataSection__column text-center">
                  <div className="dataCount textGradient">
                    <span className="counter" data-to="55"></span>%
                  </div>
                  <div className="dataCntnt">
                    <p>
                      Margins <br /> (Net Profit){" "}
                    </p>
                  </div>
                </div>
                <div className="dataSection__column text-center">
                  <div className="dataCount textGradient">
                    <span className="counter" data-to="2"></span>M
                  </div>
                  <div className="dataCntnt">
                    <p>
                      More hosts <br /> needed{" "}
                    </p>
                  </div>
                </div>
                <div className="dataSection__column text-center">
                  <div className="dataCount textGradient">
                    <span className="counter" data-to="8"></span>+
                  </div>
                  <div className="dataCntnt">
                    <p>
                      Markets around <br /> the country{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="wideSection wideCommonstyle strDemandSection">
          <div className="container-fluid">
            <div className="wideSection__row d-flex align-items-center justify-content-between">
              <div
                className="wideSection__img roundedCorner__right commonBoxshadow "
                data-emergence="visible"
              >
                <Img src={require("assets/images/strDemand-img.jpg")} />
              </div>
              <div className="wideSection__content">
                <div
                  className="commonHeading blueClr animated fdeIn__Up"
                  data-emergence="visible"
                >
                  STRs are <br /> In Demand..{" "}
                </div>
                <h3 className="regular">
                  The reliance on STRs via platforms like Airbnb, VRBO etc. are
                  going up. Pandemic or not.
                </h3>
                <Link
                  to="/request-invite"
                  className="commonBtn blueBg d-flex align-items-center justify-content-center"
                >
                  <span>Request invite</span>
                  <Img src={require("assets/images/svg/arrow.svg")} />
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="yearsSection">
          <div className="container">
            <div className="title__wrapper">
              <div
                className="commonHeading bold animated fdeIn__Up"
                data-emergence="visible"
              >
                Airbnb through <br /> the years.{" "}
              </div>
            </div>
            <div className="slides-numbers" id="yearsSliderCounter">
              <span className="active">01</span> -{" "}
              <span className="total"></span>
            </div>
            <div className="yearsSlider">
              <div className="yearsSlider__item">
                <div className="blackCard blackBg commonBoxshadow">
                  <div className="blackCard__inner d-flex flex-column">
                    <div className="blackCard__year">
                      <h2 className="blueClr">1995</h2>
                    </div>
                    <div className="blackCard__hdng">
                      <h3 className="blueClr">VRBO Starts</h3>
                    </div>
                    <div className="blackCard__cntnt">
                      <p>
                        It’s the launch of what would be the start of a massive
                        industry.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="yearsSlider__item">
                <div className="blackCard blackBg commonBoxshadow">
                  <div className="blackCard__inner d-flex flex-column">
                    <div className="blackCard__year">
                      <h2 className="blueClr">1996</h2>
                    </div>
                    <div className="blackCard__hdng">
                      <h3 className="blueClr">Booking.com Starts</h3>
                    </div>
                    <div className="blackCard__cntnt">
                      <p>From Expedia.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="yearsSlider__item">
                <div className="blackCard blackBg commonBoxshadow">
                  <div className="blackCard__inner d-flex flex-column">
                    <div className="blackCard__year">
                      <h2 className="blueClr">2000s</h2>
                    </div>
                    <div className="blackCard__hdng">
                      <h3 className="blueClr">HomeAway Merger</h3>
                    </div>
                    <div className="blackCard__cntnt">
                      <p>
                        5 different conglomerates come together under the
                        HomeAway brand.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="yearsSlider__item">
                <div className="blackCard blackBg commonBoxshadow">
                  <div className="blackCard__inner d-flex flex-column">
                    <div className="blackCard__year">
                      <h2 className="blueClr">2008</h2>
                    </div>
                    <div className="blackCard__hdng">
                      <h3 className="blueClr">Airbnb Starts</h3>
                    </div>
                    <div className="blackCard__cntnt">
                      <p>
                        The most popular STR platform launches. No one knows it
                        just yet.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="yearsSlider__item">
                <div className="blackCard blackBg commonBoxshadow">
                  <div className="blackCard__inner d-flex flex-column">
                    <div className="blackCard__year">
                      <h2 className="blueClr">2010s</h2>
                    </div>
                    <div className="blackCard__hdng">
                      <h3 className="blueClr">Traction Starts to Occur</h3>
                    </div>
                    <div className="blackCard__cntnt">
                      <p>
                        Airbnb starts to drastically scale into peoples homes
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="yearsSlider__item">
                <div className="blackCard blackBg commonBoxshadow">
                  <div className="blackCard__inner d-flex flex-column">
                    <div className="blackCard__year">
                      <h2 className="blueClr">Today</h2>
                    </div>
                    <div className="blackCard__hdng">
                      <h3 className="blueClr">STRs are Booming</h3>
                    </div>
                    <div className="blackCard__cntnt">
                      <p>
                        It’s the launch of what would be the start of a massive
                        industry.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="wideSection wideCommonstyle wideSectionReverse strTravelers">
          <div className="container-fluid">
            <div className="wideSection__row d-flex align-items-center justify-content-between">
              <div
                className="wideSection__img roundedCorner__right commonBoxshadow "
                data-emergence="visible"
              >
                <Img src={require("assets/images/hotel-img.jpg")} />
              </div>
              <div className="wideSection__content">
                <div
                  className="commonHeading blueClr textGradient"
                  id="element-waypoint"
                >
                  <span className="counter" data-to="60"></span>%
                </div>
                <h3 className="regular">
                  of STR travelers are under 40, prefer flexible work/life,
                  prioritize experiences in homes vs. hotels and consider
                  themselves avid travelers.
                </h3>
                <Link
                  to="/request-invite"
                  className="commonBtn blueBg d-flex align-items-center justify-content-center"
                >
                  <span>Request invite</span>
                  <Img src={require("assets/images/svg/arrow.svg")} />
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="overviewSection">
          <div className="container">
            <div className="title__wrapper">
              <div
                className="commonHeading blueClr animated fdeIn__Up"
                data-emergence="visible"
              >
                Growth Demand <br />
                Overview of Airbnb{" "}
              </div>
            </div>
            <div className="overview overviewGrid">
              <div className="overviewGrid__item">
                <div className="overview__box">
                  <div className="img">
                    <Img src={require("assets/images/svg/paper-fold.svg")} />
                  </div>
                  <div className="content">
                    <h3>
                      Non <br /> Institutionalized{" "}
                    </h3>
                    <p>At least not yet</p>
                  </div>
                </div>
              </div>
              <div className="overviewGrid__item">
                <div className="overview__box">
                  <div className="img">
                    <Img src={require("assets/images/svg/screen-icon.svg")} />
                  </div>
                  <div className="content">
                    <h3>Growing Demand</h3>
                    <p>Airbnb estimates it needs millions more hosts</p>
                  </div>
                </div>
              </div>
              <div className="overviewGrid__item">
                <div className="overview__box">
                  <div className="img">
                    <Img src={require("assets/images/svg/growth-icon.svg")} />
                  </div>
                  <div className="content">
                    <h3>Slow Host Growth</h3>
                    <p>Hosts aren’t joining the STR platforms to meet demand</p>
                  </div>
                </div>
              </div>
              <div className="overviewGrid__item">
                <div className="overview__box">
                  <div className="img">
                    <Img
                      src={require("assets/images/svg/propertyBlue-icon.svg")}
                    />
                  </div>
                  <div className="content">
                    <h3>Pandemic Friendly</h3>
                    <p>
                      With or without Covid-19, the demand will continue to rise
                    </p>
                  </div>
                </div>
              </div>
              <div className="overviewGrid__item">
                <div className="overview__box">
                  <div className="img">
                    <Img
                      src={require("assets/images/svg/propertyBlue-icon.svg")}
                    />
                  </div>
                  <div className="content">
                    <h3>Better Experiences</h3>
                    <p>
                      Those seeking better adventures choose STRs as they better
                      support communities
                    </p>
                  </div>
                </div>
              </div>
              <div className="overviewGrid__item">
                <div className="overview__box">
                  <div className="img">
                    <Img src={require("assets/images/svg/tag-icon.svg")} />
                  </div>
                  <div className="content">
                    <h3>
                      Amazing <br /> Profit Gains{" "}
                    </h3>
                    <p>
                      This is where more text will go for information on the
                      profit gains example
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="dataBlueSection">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div
                  className="dataCount whitegradient animated fdeIn__Up"
                  data-emergence="visible"
                >
                  $ <span className="counter" data-to="200"></span>
                  <sub>+</sub>
                </div>
              </div>
              <div className="col-md-6">
                <div className="dataBlueSection__cntnt">
                  <div
                    className="commonHeading whiteClr animated fdeIn__Up"
                    data-emergence="visible"
                  >
                    The minimum nightly rate we target
                  </div>
                  <p className="h4 regular whiteClr">
                    Our markets include submarkets in AZ, CO, FL & more. Our
                    average nightly rate fetches premiums because of our design,
                    functionality, experience, reviews and automation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="featuredSection">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="featuredSection__cntnt">
                  <div
                    className="commonHeading animated fdeIn__Up"
                    data-emergence="visible"
                  >
                    Featured Data
                  </div>
                  <div className="slides-numbers" id="featuredSliderCounter">
                    <span className="active">01</span> -{" "}
                    <span className="total"></span>
                  </div>
                  <div className="contentSlider">
                    <div className="item">
                      <p className="h2 regular">
                        Adoption is <br /> Growing <br /> (Second Measure){" "}
                      </p>
                    </div>
                    <div className="item">
                      <p className="h2 regular">
                        Market Share is <br /> Growing <br /> (Second Measure){" "}
                      </p>
                    </div>
                    <div className="item">
                      <p className="h2 regular">
                        Bookings are <br /> ticking up <br /> (airDNA){" "}
                      </p>
                    </div>
                    <div className="item">
                      <p className="h2 regular">
                        Better Operations <br /> = Better Returns <br />{" "}
                        (airDNA){" "}
                      </p>
                    </div>
                    <div className="item">
                      <p className="h2 regular">
                        Demand is <br /> outpacing <br /> Supply{" "}
                      </p>
                    </div>
                    <div className="item">
                      <p className="h2 regular">
                        Growth Rate is <br /> Increasing <br /> (airDNA){" "}
                      </p>
                    </div>
                    <div className="item">
                      <p className="h2 regular">
                        Revenue is <br /> increasing <br /> (stayFi){" "}
                      </p>
                    </div>
                    <div className="item">
                      <p className="h2 regular">
                        ADRs are <br /> Increasing <br /> (airDNA){" "}
                      </p>
                    </div>
                    <div className="item">
                      <p className="h2 regular">
                        Nightly rates <br /> are increasing <br /> (iGMS){" "}
                      </p>
                    </div>
                    <div className="item">
                      <p className="h2 regular">
                        STR travelers <br /> are increasing <br /> (stayFi){" "}
                      </p>
                    </div>
                    <div className="item">
                      <p className="h2 regular">
                        Rural, domestic destinations are winning (Home2go)
                      </p>
                    </div>
                    <div className="item">
                      <p className="h2 regular">
                        Domestic Travel <br /> is preferred <br /> (Home2go){" "}
                      </p>
                    </div>
                    <div className="item">
                      <p className="h2 regular">
                        Demand is <br /> increasing post <br /> pandemic <br />{" "}
                        (Rental Scaleup){" "}
                      </p>
                    </div>
                    <div className="item">
                      <p className="h2 regular">
                        Weekly RevPar <br /> is increasing <br /> (airDNA){" "}
                      </p>
                    </div>
                  </div>
                  <div className="featuredSection__arrows col-auto">
                    <button
                      className="slickPreviousBtn slick-prev slick-arrow"
                      aria-label="Previous"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 54.566 40.505"
                      >
                        <g
                          id="Group_799"
                          data-name="Group 799"
                          transform="translate(2511.307 -439.569) rotate(180)"
                        >
                          <g
                            id="Group_279"
                            data-name="Group 279"
                            transform="translate(2456.99 -479.721)"
                          >
                            <path
                              id="Path_1"
                              data-name="Path 1"
                              d="M19.9,0,0,22.018l1.452,1.311L18.921,4V53.943h1.955V4L38.347,23.329,39.8,22.018Z"
                              transform="translate(53.943 0) rotate(90)"
                              strokeWidth="0.5"
                            />
                          </g>
                        </g>
                      </svg>
                    </button>
                    <button
                      className="slickNextBtn slick-next slick-arrow"
                      aria-label="Next"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 54.566 40.505"
                      >
                        <g
                          id="Group_798"
                          data-name="Group 798"
                          transform="translate(-2456.74 480.074)"
                        >
                          <g
                            id="Group_279"
                            data-name="Group 279"
                            transform="translate(2456.99 -479.721)"
                          >
                            <path
                              id="Path_1"
                              data-name="Path 1"
                              d="M19.9,0,0,22.018l1.452,1.311L18.921,4V53.943h1.955V4L38.347,23.329,39.8,22.018Z"
                              transform="translate(53.943 0) rotate(90)"
                              strokeWidth="0.5"
                            />
                          </g>
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="featuredSection__data commonBoxshadow">
                  <div className="dataslider">
                    <div className="item">
                      <Img
                        src={require("assets/images/pasted-image-0-1.png")}
                      />
                    </div>
                    <div className="item">
                      <Img src={require("assets/images/grid-img-1.png")} />
                    </div>
                    <div className="item">
                      <Img src={require("assets/images/grid-img-2.png")} />
                    </div>
                    <div className="item">
                      <Img src={require("assets/images/grid-img-3.png")} />
                    </div>
                    <div className="item">
                      <Img src={require("assets/images/grid-img-4.png")} />
                    </div>
                    <div className="item">
                      <Img src={require("assets/images/grid-img-5.png")} />
                    </div>
                    <div className="item">
                      <Img src={require("assets/images/grid-img-6.png")} />
                    </div>
                    <div className="item">
                      <Img src={require("assets/images/grid-img-7.png")} />
                    </div>
                    <div className="item">
                      <Img src={require("assets/images/grid-img-8.png")} />
                    </div>
                    <div className="item">
                      <Img src={require("assets/images/grid-img-9.png")} />
                    </div>
                    <div className="item">
                      <Img src={require("assets/images/grid-img-10.png")} />
                    </div>
                    <div className="item">
                      <Img src={require("assets/images/grid-img-11.png")} />
                    </div>
                    <div className="item">
                      <Img src={require("assets/images/grid-img-12.png")} />
                    </div>
                    <div className="item">
                      <Img src={require("assets/images/grid-img-13.png")} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="wideSection wideCommonstyle selfhostingSection">
          <div className="container-fluid">
            <div className="wideSection__row d-flex align-items-center justify-content-between">
              <div
                className="wideSection__img roundedCorner__right commonBoxshadow "
                data-emergence="visible"
              >
                <Img src={require("assets/images/self-hosting-img.jpg")} />
              </div>
              <div className="wideSection__content">
                <div
                  className="commonHeading blueClr animated fdeIn__Up"
                  data-emergence="visible"
                >
                  The Problem is in Self-Hosting
                </div>
                <h3 className="regular">
                  The STR space is full of non-institutional operators aka “moms
                  and pops” who aren’t using technology, tracking expenses or
                  optimizing for growth. Hence, that is the opportunity.
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section className="moousetrapSection">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="moousetrapSection__heading">
                  <div
                    className="commonHeading blackClr animated fdeIn__Up"
                    data-emergence="visible"
                  >
                    So, we <br />
                    built a better <br /> mousetrap.{" "}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="moousetrapSection__listing">
                  <ul className="list-style-none">
                    <li>Acquisition</li>
                    <li>Property Management</li>
                    <li>Clean Design</li>
                    <li>Professional Photography</li>
                    <li>Integrated Lending</li>
                    <li>Tax Optimized</li>
                    <li>Economies of Scale</li>
                    <li>
                      Automated Price <br /> Optimization{" "}
                    </li>
                    <li>24/7 Guest Experience</li>
                    <li>Hosting on Autopilot</li>
                    <li>Lower CapEX exposure</li>
                    <li>& More</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <Review className="clientSection" />
      <GoRequest
        img={require(`assets/images/dataBtm-img.jpg`)}
        title={
          <>
            Want to see more data? <br /> Talk to Us.
          </>
        }
      />
    </Template>
  );
};
