import { Link } from "react-router-dom";
import Banner from "components/Banner";
import Template from "./Template";
import GoRequest from "components/GoRequest";

export default ({ title, className = "" }) => {
  return (
    <Template title={title} className={className}>
      <Banner
        align="start"
        className="aboutBanner privacySec"
        banner={require("assets/images/privacy-policy.jpg")}
        title={
          <>
            Privacy
            <span className="blueClr"> Policy</span>
          </>
        }
      >
        <div className="bannerSection__leftmdm">
          <h3 className="medium">
            <span className="blueClr">Protecting</span> your privacy is important to us.
          </h3>
        </div>
        <h5 className="regular">
          This Privacy Policy (the “Privacy Policy“) is meant to help you (“you”) understand how Techvestor LLC (“Techvestor,” “we,” “our,” or “us”)
          collect, use and share your personal information and to assist you in exercising the privacy rights available to you.
        </h5>
      </Banner>
      <>
        <section className="privacyinfo">
          <div className="container">
            <div className="privacyinfo__inner">
              <div className="privacyinfo__hldr">
                <div className="privacyinfo__head animated fadeIn__Down" data-emergence="visible">
                  <h1 className="blueClr">Scope</h1>
                </div>
                <div className="privacyinfo__txt">
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    This Privacy Policy applies to personal information processed by us in our business, including on our websites, mobile
                    applications, and other online or offline offerings (collectively, the <strong>“Services“</strong>).
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    This Privacy Policy does not apply to any third-party websites, services or applications, even if they are accessible through our
                    Services. Also, please note that, unless we define a term differently in this Privacy Policy, all capitalized terms used in this
                    Privacy Policy have the same meanings as in our Terms of Use. So, please make sure that you have read, understand and agree to our
                    Terms of Use (the <strong> “Terms”</strong>).
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    This Privacy Policy applies to our operating divisions, subsidiaries, affiliates, and branches, including any additional
                    subsidiary, affiliate, or branch that we may subsequently form. We do not and will not sell or rent your personal information to
                    anyone, for any reason, at any time.
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    Techvestor uses and discloses your personal information only as follows:
                  </p>
                  <ul className="animated fadeIn__Down" data-emergence="visible">
                    <li>
                      * to third-party organizations that provide services to Techvestor to fulfill your request (e.g. to process your buy or sell
                      transaction) and are bound by these same privacy restrictions;
                    </li>
                    <li>
                      * unless you ask us not to, we may contact you via email in the future to tell you about specials, new service offerings, or
                      changes to this Privacy Policy;
                    </li>
                    <li>* to analyze site usage and improve the Services;</li>
                    <li>* to deliver to you any administrative notices, alerts and communications relevant to your use of the Services;</li>
                    <li>* to fulfill your requests for certain services and transactions;</li>
                    <li>
                      * for market research, project planning, troubleshooting problems, detecting and protecting against error, fraud or other
                      criminal activity;
                    </li>
                    <li>to enforce Techvestor’s Terms of Use; and</li>
                    <li>* as otherwise set forth in this Privacy Policy.</li>
                    <li>
                      *In the event that you access the Services as brought to you by our partners or service providers, through a co-branded URL or
                      by web services, your email address used for registration on the Services may be provided to that partner or service provider to
                      fulfill your request.
                    </li>
                  </ul>
                </div>
              </div>
              {/*  */}
              <div className="privacyinfo__hldr">
                <div className="privacyinfo__head animated fadeIn__Down" data-emergence="visible">
                  <h1 className="blueClr">How We Limit the Collection and Use of Personal Information</h1>
                  <h3>Registration</h3>
                </div>
                <div className="privacyinfo__txt">
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    Certain areas and features of Techvestor.com are available to you without registration or the need to provide us any information.
                    However, other features of the Site or the Services may require registration, which involves provision to Techvestor of an email
                    address, a password and additional details for fulfilling your service requests and to participate on our third party sites, a
                    username <strong> (collectively the “Registration or Sign Up Information”)</strong>.
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    In order to benefit from the full functionality of the Service, you may also be asked to provide additional information to all
                    Techvestor to share more data for your use.
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    From time to time we may request other personal information to provide you with other benefits of the Service such as application
                    for a loan or payment instructions for purchasing a property. In all such instances, you will be given the opportunity to provide
                    or to decline to provide that information, and it will be used only for the stated purpose.
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    By providing the information, you are also agreeing to share the information with other Service providers such as Property
                    Provider, lender, insurance company, Title company, Escrow/ Closing Attorney, inspection company, appraisal company and others for
                    fulfilling your buy/ sell/ manage service requests.
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    Techvestor may make anonymous or aggregate personal information and disclose such data only in a non-personally identifiable
                    manner to:
                  </p>
                  <ul className="animated fadeIn__Down" data-emergence="visible">
                    <li>
                      * Advertisers and other third parties for marketing and promotional purposes, such as the number of users who applied for a loan
                      or how many users clicked on a particular Offer from Techvestor;
                    </li>
                    <li>* Organizations approved by Techvestor that conduct research into investment purchases; and</li>
                    <li>* Users of the Service for purposes of comparison of their personal financial situation relative to the broader market.</li>
                  </ul>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    Such information does not identify you individually. Access to your Registration Information, other Credentials, and any other
                    personal information you provide is strictly restricted and used in accordance with specific internal procedures and safeguard
                    governing access, in order to operate, develop or improve the Service. These individuals have been selected in accordance with our
                    security policies and practices and are bound by confidentiality obligations. We may also use third party vendors or service
                    providers to help us provide the Service to you, such as sending e-mail messages on our behalf or hosting and operating a
                    particular feature or functionality of the Service or a lender processing your loan application. Our contracts with these third
                    parties outline the appropriate use and handling of your information and prohibit them from using any of your personal information
                    for purposes unrelated to the product or service they’re providing. We require such third parties to maintain the confidentiality
                    of the information we provide to them.
                  </p>
                  <h3 className="animated fadeIn__Down" data-emergence="visible">
                    How To Change Registration Information
                  </h3>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    If your Registration Information changes, you may update it any time via the Services.
                  </p>
                </div>
              </div>
              {/*  */}
              <div className="privacyinfo__hldr">
                <div className="privacyinfo__head animated fadeIn__Down" data-emergence="visible">
                  <h1 className="blueClr">How We Use Online Session Information To Improve Your Experience</h1>
                  <h3>Information Collection</h3>
                </div>
                <div className="privacyinfo__txt">
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    When you visit Techvestor.com, we may collect technical and navigational information, such as computer browser type, Internet
                    protocol address, pages visited, and average time spent on our Site. This information may be used, for example, to alert you to
                    software compatibility issues, or it may be analyzed to improve our Web design and functionality.
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    <strong>“Cookies” </strong> are alphanumeric identifiers in the form of text files that are inserted and stored by your Web
                    browser on your computer’s hard drive. Techvestor may set and access cookies on your computer to track and store preferential
                    information about you. Techvestor may gather information about you through cookie technology.
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    Web beacons or zero or single byte tags are embedded in a Web page or email for the purpose of measuring and analyzing site usage
                    and activity. Techvestor, or third party service providers acting on our behalf, may use Web beacons to help us analyze Site usage
                    and improve the Service.
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    We may use third party service providers to help us analyze certain online activities. For example, these service providers may
                    help us measure the performance of our online and email campaigns or analyze visitor activity on Techvestor.com and related sites.
                    We may permit these service providers to use cookies and other technologies to perform these services for Techvestor. We do not
                    share any personal information about our customers with these third party service providers, and these service providers do not
                    collect such information on our behalf. Our third party service providers are required to comply fully with this Privacy Policy.
                  </p>
                </div>
              </div>
              {/*  */}
              <div className="privacyinfo__hldr">
                <div className="privacyinfo__head animated fadeIn__Down" data-emergence="visible">
                  <h1 className="blueClr">Third Parties</h1>
                </div>
                <div className="privacyinfo__txt">
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    Third parties offering other services will not be given your personal information without your permission.
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    There are a number of separate products and services offered by third parties advertised by us on our Site and through the Service
                    that may be complementary to your use of Techvestor.com, such as lenders, investment companies, retirement services providers, and
                    brokerage institutions
                    <strong>(collectively, “Techvestor Offers”).</strong>
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    If you choose to use these separate products or services, disclose information to the providers, or grant them permission to
                    collect information about you, then their use of your information is governed by their privacy policies. You should evaluate the
                    practices of external services providers before deciding to use their services. Techvestor is not responsible for their privacy
                    practices. If you click on a link to a third party site, Techvestor encourages you to check the privacy policy of that site.
                    Techvestor may present links in a format that enables us to keep track of whether these links have been followed and whether any
                    action has been taken on a third party Web site. We use this information to improve the quality of the Techvestor Offers and
                    customized content on the Service.
                  </p>
                </div>
              </div>
              {/*  */}
              <div className="privacyinfo__hldr">
                <div className="privacyinfo__head animated fadeIn__Down" data-emergence="visible">
                  <h1 className="blueClr">Social Media & Blog</h1>
                </div>
                <div className="privacyinfo__txt">
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    If you use a social media, bulletin board, blog or chat room from our Site, you should be aware that any information you submit
                    there can be read, collected or used by other users and could be used to send you unsolicited messages. We are not responsible for
                    the personal information you choose to submit in these online media. These media may be hosted by Techvestor or by a third party
                    service providers.
                  </p>
                </div>
              </div>
              {/*  */}
              <div className="privacyinfo__hldr">
                <div className="privacyinfo__head animated fadeIn__Down" data-emergence="visible">
                  <h1 className="blueClr">Contests, Giveaways, Surveys & Feedback</h1>
                </div>
                <div className="privacyinfo__txt">
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    From time to time, Techvestor may offer you the opportunity to participate in contests, giveaways and other promotions. Any
                    information submitted in connection with such activities will be treated in accordance with this Privacy Policy, except as
                    specifically set forth in the rules for those contests, giveaways or promotions. From time to time, Techvestor may also ask you to
                    participate in surveys and feedback designed to help Techvestor improve the Site. Any personal information provided to Techvestor
                    in connection with any survey will be used only in relation to that survey and as elsewhere set forth in this Policy.
                  </p>
                </div>
              </div>
              {/*  */}
              <div className="privacyinfo__hldr">
                <div className="privacyinfo__head animated fadeIn__Down" data-emergence="visible">
                  <h1 className="blueClr">Children’s Policy</h1>
                </div>
                <div className="privacyinfo__txt">
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    For protecting the privacy of young children, Techvestor does not knowingly collect or solicit personal information from anyone
                    under the age of 13. If you are under 13, do not share any information about yourself to us, including your name, address,
                    telephone number, or email address. No one under age 13 is allowed to provide any personal information to Techvestor. In the event
                    that we learn that we have collected personal information from a child under age 13 without verification of parental consent, we
                    will delete that information as quickly as possible. As a parent you are responsible for any transactions or services requested
                    from Techvestor.
                  </p>
                </div>
              </div>
              {/*  */}
              <div className="privacyinfo__hldr">
                <div className="privacyinfo__head animated fadeIn__Down" data-emergence="visible">
                  <h1 className="blueClr">Information Disclosure</h1>
                </div>
                <div className="privacyinfo__txt">
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    Notwithstanding the foregoing, Techvestor reserves the right (and you authorize Techvestor) to share or disclose your personal
                    information when Techvestor determines, in its sole discretion, that the disclosure of such information is necessary or
                    appropriate:
                  </p>
                  <ul className="animated fadeIn__Down" data-emergence="visible">
                    <li>
                      * To enforce our rights against you or in connection with a breach by you of this Privacy Policy or the Techvestor.com Terms of
                      Use;
                    </li>
                    <li>* To prevent prohibited or illegal transaction or activities; or</li>
                    <li>* When required by any applicable law, rule regulation, subpoena or other legal process.</li>
                  </ul>
                </div>
              </div>
              {/*  */}
              <div className="privacyinfo__hldr">
                <div className="privacyinfo__head animated fadeIn__Down" data-emergence="visible">
                  <h1 className="blueClr">Data Transference</h1>
                </div>
                <div className="privacyinfo__txt">
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    Your data may be transferred upon change of control but only in accordance with this Privacy Policy. Personal information may be
                    transferred to a third party as a result of a sale, acquisition, merger, reorganization or other change of control. If we sell,
                    merge or transfer any part of our business, part of the sale may include your personal information. If so, you will be asked if
                    you’d like to stop receiving promotional information following any change of control.
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    Your data is yours. You can request us to remove it anytime you want. When you request us to delete your account for the Service,
                    your data will be permanently expunged from our primary production servers and further access to your account will not be
                    possible. We will also promptly disconnect any connection we had established to your Account Information and delete all related
                    credentials. However, portions of your data, consisting of aggregate data derived from your Account Information, may remain on our
                    production servers indefinitely. Your data may also remain on a backup server or media. Techvestor keeps these backups to ensure
                    our continued ability to provide the Service to you in the event of malfunction or damage to our primary production servers. We
                    also reserve the right to use any aggregated or anonymous data derived from or incorporating your personal information.
                  </p>
                </div>
              </div>
              {/*  */}
              <div className="privacyinfo__hldr">
                <div className="privacyinfo__head animated fadeIn__Down" data-emergence="visible">
                  <h1 className="blueClr">Electronic Communications</h1>
                </div>
                <div className="privacyinfo__txt">
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    We provide our users with periodic newsletter, summaries of their property portfolios and email alerts. We also allow users to
                    subscribe to email newsletters and from time to time may transmit emails promoting Techvestor or third-party goods or services.
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    Techvestor.com subscribers have the ability to opt-out of receiving our promotional emails and to terminate their subscriptions by
                    following the instructions in the emails. Opting out in this manner will not end transmission of service-related emails, such as
                    email alerts.
                  </p>
                </div>
              </div>
              {/*  */}
              <div className="privacyinfo__hldr">
                <div className="privacyinfo__head animated fadeIn__Down" data-emergence="visible">
                  <h1 className="blueClr">Confidential Information</h1>
                  <h5 className="upper-case ">
                    YOU ARE RESPONSIBLE FOR MAINTAINING THE CONFIDENTIALITY OF YOUR LOGIN ID, PASSWORD OR ANY LINKS SENT TO YOU RELATED TO YOUR
                    INFORMATION.
                  </h5>
                </div>
                <div className="privacyinfo__txt">
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    We maintain strict rules to help prevent others from guessing your password. We also recommend that you change your password
                    periodically. You are responsible for maintaining the security of your Login ID and Password. You may not provide these
                    credentials to any third party. If you believe that they have been stolen or been made known to others, you must contact us
                    immediately at
                    <Link to="/mailto:hello@techvestor.com">hello@techvestor.com.</Link>
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    In any event you should change your password immediately via the Service. We are not responsible if someone else accesses your
                    account through Registration Information they have obtained from you or through a violation by you of this Privacy Policy or the
                    Techvestor.com Terms of Use.
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    If you are forwarding any links related to data or otherwise provided by Techvestor, you are doing so at your own risk. Techvestor
                    cannot be held liable for any consequences of such sharing.
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    If you have a security related concern, please contact us at hello@techvestor.com. We will work closely with you to ensure a rapid
                    and personal response to your concerns.
                  </p>
                </div>
              </div>
              {/*  */}
              <div className="privacyinfo__hldr">
                <div className="privacyinfo__head animated fadeIn__Down" data-emergence="visible">
                  <h1 className="blueClr">Privacy Policy Updates</h1>
                </div>
                <div className="privacyinfo__txt">
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    We update this Privacy Policy periodically. We post updates on our website whenever there is a change to our privacy and security
                    policy. The date last revised appears at the top of the Privacy Policy. Changes take effect immediately upon posting.
                  </p>
                </div>
              </div>
              {/*  */}
              <div className="privacyinfo__hldr">
                <div className="privacyinfo__head animated fadeIn__Down" data-emergence="visible">
                  <h1 className="blueClr">California Requirements</h1>
                </div>
                <div className="privacyinfo__txt">
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    In compliance with the California Consumer Privacy Act, California residents may request that (a) we inform them of what
                    information we collect, use, and disclose about them, and (b) we delete the information we have collected about them (subject to
                    certain statutory exemptions). If California residents would like to know more or submit a request, please email us at
                    hello@techvestor.com.
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    California residents may designate an authorized agent to make a request on their behalf under this Section. To designate an
                    authorized agent to make a request on your behalf, you must provide a valid power of attorney and a form of government issued
                    identification for both the requester and authorized agent
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    California residents will not be charged or otherwise discriminated against for exercising their rights under this Section.
                  </p>
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    As discussed above, we do not and will not sell your information. As such, we do not provide a process to opt-out of the sale of
                    your information.
                  </p>
                </div>
              </div>
              {/*  */}
              <div className="privacyinfo__hldr">
                <div className="privacyinfo__head animated fadeIn__Down" data-emergence="visible">
                  <h1 className="blueClr">Questions? Concerns? Suggestions?</h1>
                </div>
                <div className="privacyinfo__txt">
                  <p className="animated fadeIn__Down" data-emergence="visible">
                    Please contact us at <Link to="/mailto:hello@techvestor.com">hello@techvestor.com</Link> to report any violations of this Privacy
                    Policy or to pose any questions regarding this Privacy Policy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <GoRequest img={require(`assets/images/policy-btm.jpg`)} />
    </Template>
  );
};
