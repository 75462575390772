import $ from "jquery";
import logoImg from "assets/images/svg/logo.svg";
import arrowImg from "assets/images/svg/arrow.svg";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import Img from "./Img";

const Header = () => {
  const domHeader = useRef(null);
  const domHeaderNavigation = useRef(null);
  const menuIconRef = useRef(null);

  useEffect(() => {
    window.new_scroll_position = 0;
    window.last_scroll_position = 0;
    const header = domHeader.current;

    const scrollHandler = function (e) {
      var navhldr = $(header);
      var top = 20;
      if ($(window).scrollTop() >= top) {
        navhldr.addClass("fixed");
      } else {
        navhldr.removeClass("fixed");
      }

      window.last_scroll_position = window.scrollY;

      if (
        window.new_scroll_position < window.last_scroll_position &&
        window.last_scroll_position > 80
      ) {
        header.classList.remove("slideDown");
        header.classList.add("slideUp");

        // Scrolling up
      } else if (window.new_scroll_position > window.last_scroll_position) {
        header.classList.remove("slideUp");
        header.classList.add("slideDown");
      }

      window.new_scroll_position = window.last_scroll_position;
    };

    $(window).on("scroll", scrollHandler);

    return () => {
      $(window).off("scroll", scrollHandler);
      delete window.new_scroll_position;
      delete window.last_scroll_position;
    };
  }, []);

  const handleMenuClick = (e) => {
    $(e.currentTarget).toggleClass("active");
    $("body").toggleClass("scroll");
    $(domHeaderNavigation.current).toggleClass("toggle");
    $(domHeaderNavigation.current).slideToggle();
  };

  return (
    <>
      <header className="headerSection" ref={domHeader}>
        <div className="container">
          <div className="headerSection__holder">
            <div className="headerSection__logo">
              <Link to="/">
                <Img src={logoImg} />
              </Link>
            </div>
            <div
              className="headerSection__navigation"
              ref={domHeaderNavigation}
            >
              <nav className="headerSection__navbar">
                <ul className="list-style-none">
                  <li>
                    <a href="/about">About</a>
                  </li>
                  <li>
                    <a href="/data">Data</a>
                  </li>
                  <li>
                    <a href="/strategy">Strategy</a>
                  </li>
                  <li>
                    <a href="/how-it-works">How it works</a>
                  </li>
                  <li>
                    <a href="/press">Press</a>
                  </li>
                  <li>
                    <a href="/portfolio">Our Portfolio</a>
                  </li>
                  <li>
                    <a
                      href="https://techvestor.investnext.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Investor portal
                    </a>
                  </li>
                </ul>
              </nav>
              <a
                href="/request-invite"
                className="commonBtn blueBg requestBtn d-flex align-items-center justify-content-center"
              >
                <span>Request Invite</span>
                <Img src={arrowImg} />
              </a>
            </div>
            <div
              className="menu-icon"
              onClick={handleMenuClick}
              ref={menuIconRef}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </header>
      <div className="header__height"></div>
    </>
  );
};

export default Header;
