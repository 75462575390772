import { useEffect, useLayoutEffect } from "react";
import { Helmet } from "react-helmet";

export default ({ children, title, className = "", ...others }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    if (window.customInit) {
      window.customInit();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Techvestor - {title}</title>
      </Helmet>
      <main className={`middle ${className}`} {...others}>
        {children}
      </main>
    </>
  );
};
