import { Link } from "react-router-dom";
import Banner from "components/Banner";
import Template from "./Template";
import GoRequest from "components/GoRequest";
import Review from "components/Review";
import { useLayoutEffect } from "react";
import Img from "components/Img";

export default ({ title, className = "" }) => {
  useLayoutEffect(() => {
    if (window.benefitSlider) {
      window.benefitSlider();
    }
    if (window.investSlider) {
      window.investSlider();
    }
  }, []);

  return (
    <Template title={title} className={className}>
      <Banner
        banner={require("assets/images/homeBanner.jpg")}
        className="homeBanner"
        title={
          <>
            The Strategic Way <br />
            to <span className="blueClr">Invest</span> in Short Term Rental
            Investment Properties.
          </>
        }
      >
        <p>
          Passively invest in short-term rentals with as little as $25,000.
          We’ll do all the work and send you a check every quarter.
          <span className="bold blueClr"> You invest we do the rest.</span>
        </p>
        <div className="btn__wrapper d-flex align-items-center">
          <Link
            to="/request-invite"
            className="commonBtn blackBg d-flex align-items-center justify-content-center"
          >
            <span>Request Invite</span>
            <Img src={require("assets/images/svg/arrow.svg")} />
          </Link>
          <Link
            to="/data"
            className="commonBtn blackBg d-flex align-items-center justify-content-center"
          >
            <span>See the Data</span>
            <Img src={require("assets/images/svg/arrow.svg")} />
          </Link>
        </div>
      </Banner>
      <>
        <Review className="" />
        <section className="benefitSection">
          <div className="container">
            <div className="benefitSection__inner">
              <div className="row align-items-center justify-content-between">
                <div className="col-md-5">
                  <div className="benefitSection__title">
                    <div
                      className="commonHeading blueClr animated"
                      data-emergence="visible"
                    >
                      Cash Flow, Tax Benefits & More.
                    </div>
                  </div>
                  <div className="benefitSection__Btn">
                    <div className="slides-numbers" id="benefitCounter">
                      <span className="active">01</span> -{" "}
                      <span className="total"></span>
                    </div>
                    <div className="benefitSection__arrows col-auto">
                      <button
                        className="slickPreviousBtn slick-prev slick-arrow"
                        aria-label="Previous"
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 54.566 40.505"
                        >
                          <g
                            id="Group_799"
                            data-name="Group 799"
                            transform="translate(2511.307 -439.569) rotate(180)"
                          >
                            <g
                              id="Group_279"
                              data-name="Group 279"
                              transform="translate(2456.99 -479.721)"
                            >
                              <path
                                id="Path_1"
                                data-name="Path 1"
                                d="M19.9,0,0,22.018l1.452,1.311L18.921,4V53.943h1.955V4L38.347,23.329,39.8,22.018Z"
                                transform="translate(53.943 0) rotate(90)"
                                strokeWidth="0.5"
                              />
                            </g>
                          </g>
                        </svg>
                      </button>
                      <button
                        className="slickNextBtn slick-next slick-arrow"
                        aria-label="Next"
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 54.566 40.505"
                        >
                          <g
                            id="Group_798"
                            data-name="Group 798"
                            transform="translate(-2456.74 480.074)"
                          >
                            <g
                              id="Group_279"
                              data-name="Group 279"
                              transform="translate(2456.99 -479.721)"
                            >
                              <path
                                id="Path_1"
                                data-name="Path 1"
                                d="M19.9,0,0,22.018l1.452,1.311L18.921,4V53.943h1.955V4L38.347,23.329,39.8,22.018Z"
                                transform="translate(53.943 0) rotate(90)"
                                strokeWidth="0.5"
                              />
                            </g>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 benefitSection__anual--column">
                  <div className="benefitSection__anual--content commonBoxshadow whiteSmokeBg">
                    <div className="benefitSlider">
                      <div className="item">
                        <div className="benefitSection__graph--holder">
                          <div
                            className="benefitSection__graph--donut chartTrack"
                            data-percent="100"
                            data-final="100"
                            data-background="#4A44E2"
                          ></div>
                          <div
                            className="benefitSection__graph--donut chartBar"
                            data-percent="20"
                            data-final="20"
                            data-background="#4A44E2"
                          >
                            <span className="percent"></span>
                          </div>
                        </div>
                        <div className="benefitSection__cntnt">
                          <h2 className="medium">Annual ROI</h2>
                          <p className="h5 regular">
                            Cash Flow + Appreciation + Depreciation + Debt
                            Paydown is a powerful formula for wealth.
                          </p>
                        </div>
                      </div>
                      <div className="item">
                        <div className="benefitSection__graph--holder">
                          <div
                            className="benefitSection__graph--donut chartTrack"
                            data-percent="1"
                            data-final="100"
                            data-background="#4A44E2"
                          ></div>
                          <div
                            className="benefitSection__graph--donut chartBar"
                            data-percent="1"
                            data-final="15"
                            data-background="#4A44E2"
                          >
                            <span className="percent"></span>
                          </div>
                        </div>
                        <div className="benefitSection__cntnt">
                          <h2 className="medium">Annual Cash Flow</h2>
                          <p className="h5 regular">
                            Earn double digit yield on an annual basis to
                            achieve the passive income you want.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sectionOuter__bg">
          <div className="wideSection howitworkSection">
            <div className="container-fluid">
              <div className="wideSection__row d-flex align-items-center justify-content-between">
                <div
                  className="wideSection__img roundedCorner__right commonBoxshadow "
                  data-emergence="visible"
                >
                  <Img src={require("assets/images/how-it-work.jpg")} />
                </div>
                <div className="wideSection__content">
                  <div
                    className="commonHeading blueClr animated fdeIn__Up"
                    data-emergence="visible"
                  >
                    How it works
                  </div>
                  <div className="slides-numbers" id="howitworkCounter">
                    <span className="active">01</span> -{" "}
                    <span className="total"></span>
                  </div>
                  <div className="howitworkSlider">
                    <div className="howitworkSlider__item">
                      <h2 className="medium">Request Invite</h2>
                      <p className="h5 regular">
                        Schedule a call with our Investor Partnerships team to
                        best explore if this is a good fit for your specific
                        goals.
                      </p>
                    </div>
                    <div className="howitworkSlider__item">
                      <h2 className="medium">Let’s Talk</h2>
                      <p className="h5 regular">
                        If it’s a mutual fit, we’ll partner with you. We’ll
                        explain all the ins and outs. In short, you invest, we
                        do the rest.
                      </p>
                    </div>
                    <div className="howitworkSlider__item">
                      <h2 className="medium">We Get to Work</h2>
                      <p className="h5 regular">
                        We’ll handle everything from A to Z and soon, you’ll be
                        cash flowing with STRs while banking that equity.
                      </p>
                    </div>
                    <div className="howitworkSlider__item">
                      <h2 className="medium">Rinse & Repeat</h2>
                      <p className="h5 regular">
                        Collect your cash flow, grow your wealth and if you’re
                        up for it, rinse and repeat until you hit your financial
                        goals.
                      </p>
                    </div>
                  </div>
                  <div className="wideSection__arrows col-auto">
                    <button
                      className="slickPreviousBtn slick-prev slick-arrow"
                      aria-label="Previous"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 54.566 40.505"
                      >
                        <g
                          id="Group_799"
                          data-name="Group 799"
                          transform="translate(2511.307 -439.569) rotate(180)"
                        >
                          <g
                            id="Group_279"
                            data-name="Group 279"
                            transform="translate(2456.99 -479.721)"
                          >
                            <path
                              id="Path_1"
                              data-name="Path 1"
                              d="M19.9,0,0,22.018l1.452,1.311L18.921,4V53.943h1.955V4L38.347,23.329,39.8,22.018Z"
                              transform="translate(53.943 0) rotate(90)"
                              strokeWidth="0.5"
                            />
                          </g>
                        </g>
                      </svg>
                    </button>
                    <button
                      className="slickNextBtn slick-next slick-arrow"
                      aria-label="Next"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 54.566 40.505"
                      >
                        <g
                          id="Group_798"
                          data-name="Group 798"
                          transform="translate(-2456.74 480.074)"
                        >
                          <g
                            id="Group_279"
                            data-name="Group 279"
                            transform="translate(2456.99 -479.721)"
                          >
                            <path
                              id="Path_1"
                              data-name="Path 1"
                              d="M19.9,0,0,22.018l1.452,1.311L18.921,4V53.943h1.955V4L38.347,23.329,39.8,22.018Z"
                              transform="translate(53.943 0) rotate(90)"
                              strokeWidth="0.5"
                            />
                          </g>
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="devcontentSection">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="devcontentSection__left">
                    <div
                      className="commonHeading blueClr animated fdeIn__Up"
                      data-emergence="visible"
                    >
                      We’ve done this once or twice before…
                    </div>
                    <div className="btn__wrapper d-flex align-items-center">
                      <Link
                        to="/how-it-works"
                        className="commonBtn blackBg d-flex align-items-center justify-content-center"
                      >
                        <span>See what we do</span>
                        <Img src={require("assets/images/svg/arrow.svg")} />
                      </Link>
                      <Link
                        to="/about"
                        className="commonBtn blackBg d-flex align-items-center justify-content-center"
                      >
                        <span>Meet the team</span>
                        <Img src={require("assets/images/svg/arrow.svg")} />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="devcontentSection__right animated fdeIn__Up"
                    data-emergence="visible"
                  >
                    <p>
                      We built Techvestor after 10+ years of working for the
                      Facebooks and Apples of the world. Cash flow, tax benefits
                      and real estate gave us freedom, security and allowed us
                      to finally LIVE. Today, we’ve helped over 500+ people
                      invest in real estate, coast to coast and all across the
                      world.
                    </p>
                    <p>
                      We’re 2 ex-techies who thought there was a better way. So,
                      we spent the last decade building it ourselves. We focused
                      on cash flowing, tax efficient, self compounding real
                      estate.
                    </p>
                    <p>
                      Techvestor is our vision of the most seamless cash flow
                      experience someone can imagine. Simply want to do it and
                      it’s done. That’s our goal with building this company.
                    </p>
                    <p>We invite you to join the cash flow journey.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="noteSection"
          style={{
            backgroundImage: `url(${require(`assets/images/note-bg.jpg`)})`,
          }}
        >
          <div className="container">
            <div className="noteSection__wrap">
              <p
                className="whiteClr medium animated fdeIn__Up"
                data-emergence="visible"
              >
                Please Note: Techvestor is only open to accredited investors,
                and all investments, including this one, carry the risk of full
                loss.
              </p>
            </div>
          </div>
        </section>
        <section className="investSection">
          <div className="container">
            <div className="title__wrapper">
              <div
                className="commonHeading bold animated fdeIn__Up"
                data-emergence="visible"
              >
                You <span className="blueClr">Invest.</span> We do <br />
                the rest. It’s that easy.
              </div>
            </div>
            <div className="slides-numbers" id="investSliderCounter">
              <span className="active">01</span> -{" "}
              <span className="total"></span>
            </div>
            <div className="investSlider">
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img
                          src={require("assets/images/svg/secureDollar-icon.svg")}
                        />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">Acquisition</h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          Our propietary platform underwrites over 50,000
                          properties per month. We know what and where to buy.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img
                          src={require("assets/images/svg/dollar-icon.svg")}
                        />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">Lending</h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          No W2s or Income Statements. We’re partnering with you
                          so we’ve got this covered with our partners.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img
                          src={require("assets/images/svg/economicImp-icon.svg")}
                        />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">Finance & Accounting</h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          Our CPA and accounting team will handle all the
                          numbers and share the details with you in your
                          quarterly report.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img src={require("assets/images/svg/property.svg")} />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">Property Management</h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          33% more revenue, 35% more occupancy and 17% more
                          profit than others. Yup, that’s hot off the press 2022
                          data.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img
                          src={require("assets/images/svg/text-oprimize.svg")}
                        />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">
                          Tax <br />
                          Optimized
                        </h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          Depreciation, cost segregation, tax free income
                          anyone? We’ve got it handled. You get a K1.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img
                          src={require("assets/images/svg/smart-technology.svg")}
                        />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">Smart Technology</h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          AC, security, locks and more. All using the best
                          available tech to ensure we’re not running the
                          utilities up necessarily.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img src={require("assets/images/svg/design.svg")} />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">Design</h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          Trust us, it matters. We’ll ensure the STR stands out
                          in search rankings and fetches it’s most optimal
                          price.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img
                          src={require("assets/images/svg/auto-reviews.svg")}
                        />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">Automated Reviews</h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          Our guests love the functional, comfortable, home away
                          from home that we provide. And they review us 4.8+.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img
                          src={require("assets/images/svg/maintenance.svg")}
                        />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">Maintenance</h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          Included to ensure we can provide a stellar 24/7 guest
                          experience. Guests will love their clean stay.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img
                          src={require("assets/images/svg/protection.svg")}
                        />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">Legal</h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          You’re protected by our legal team and over a $1M
                          umbrella liability policy by working with us. Hooray!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img
                          src={require("assets/images/svg/presentation.svg")}
                        />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">Insta-Worthy Photos</h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          You eat with your eyes first. So yes, we’re going to
                          spend the money to make sure this place looks like a
                          million bucks.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img src={require("assets/images/svg/marketing.svg")} />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">Multi-Platform</h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          We automatically list across Airbnb, VRBO, Expedia and
                          more. Optimizing for the best returns along the way.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img src={require("assets/images/svg/calender.svg")} />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">
                          LLC <br />
                          Setup
                        </h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          The tax code favors the business owner. Well, now you
                          are one. Welcome to a world of deductions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img src={require("assets/images/svg/managment.svg")} />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">Revenue Management</h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          Lower expenses due to our economies of scale. We save
                          30% over the “old” way of doing things. Profits!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img src={require("assets/images/svg/reporting.svg")} />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">Reporting</h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          Quarterly reporting with real time access to your
                          dashboard when or if you want to take a peek. 100%
                          transparency.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img src={require("assets/images/svg/strategy.svg")} />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">
                          Wealth <br />
                          Strategy
                        </h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          Learn how to save 50% on your taxes. Real Estate is
                          just the beginning. Our CPAs and financial advisors
                          can help.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img src={require("assets/images/svg/identity.svg")} />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">Identity Verification</h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          We take our properties seriously and always know who’s
                          there. Rest easy that we have your back at all times.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img
                          src={require("assets/images/svg/control-panel.svg")}
                        />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">
                          Access <br />
                          Control Panel
                        </h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          See everything in your dashboard. In real time. We
                          call it the OS. (coming soon)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img
                          src={require("assets/images/svg/partnership.svg")}
                        />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">Partnership</h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          Ready to do it again? Just give us the green light.
                          You’re already approved and get first dibs as a
                          current partner.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item">
                  <div className="blackCard blackBg commonBoxshadow">
                    <div className="blackCard__inner d-flex flex-column">
                      <div className="blackCard__img">
                        <Img src={require("assets/images/svg/economies.svg")} />
                      </div>
                      <div className="blackCard__hdng">
                        <h3 className="blueClr">Economies of Scale</h3>
                      </div>
                      <div className="blackCard__cntnt">
                        <p>
                          Benefit from all the discounts we get. Dollar for
                          dollar. We pass on 100% of all savings.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="investSlider__item"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="guaranteSection">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="guaranteSection__lftCntnt">
                  <div
                    className="commonHeading animated fdeIn__Up"
                    data-emergence="visible"
                  >
                    Our Guarantee & Promise.
                  </div>
                  <Link to="/strategy" className="commonBtn blueBg">
                    <span>Get to know us</span>
                    <Img src={require("assets/images/svg/arrow.svg")} />
                  </Link>
                </div>
              </div>
              <div className="col-md-6">
                <div className="guaranteSection__rgtCntnt">
                  <div
                    className="guaranteSection__rgtCntnt--box animated fdeIn__Up"
                    data-emergence="visible"
                  >
                    <div className="imgBox">
                      <Img src={require("assets/images/svg/ac.svg")} />
                    </div>
                    <div className="cntntBox">
                      <h3>Aligned Incentives</h3>
                      <p>
                        We’ve got skin in the game and the same goals: the best
                        returns.
                      </p>
                    </div>
                  </div>
                  <div
                    className="guaranteSection__rgtCntnt--box animated fdeIn__Up"
                    data-emergence="visible"
                  >
                    <div className="imgBox">
                      <Img src={require("assets/images/svg/paper-icon.svg")} />
                    </div>
                    <div className="cntntBox">
                      <h3>Transparent from Day One</h3>
                      <p>
                        The good, the bad (if any). All shared with you, every
                        quarter.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <GoRequest
        img={require(`assets/images/homeBtm-img.jpg`)}
        seeData={true}
        title="Ready to take the first step"
        text="We’ll be with you every step of the way."
      />
    </Template>
  );
};
