import Footer from "components/Footer";
import Header from "components/Header";
import About from "pages/About";
import ContactUs from "pages/ContactUs";
import Data from "pages/Data";
import Home from "pages/Home";
import HowItWork from "pages/HowItWork";
import Performance from "pages/Performance";
import Portfolio from "pages/Portfolio";
import Press from "pages/Press";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Register from "pages/Register";
import Request from "pages/Request";
import Sorry from "pages/Sorry";
import Strategy from "pages/Strategy";
import ThankYou from "pages/ThankYou";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { resetCookies } from "utils/calendly";

function App() {
  useEffect(() => {
    resetCookies();
  }, []);
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route index element={<Home title="Home" />} />
        <Route path="/about" element={<About title="About" />} />
        <Route path="/contact-us" element={<ContactUs title="Contact Us" />} />
        <Route path="/data" element={<Data title="Data" />} />
        <Route path="/faqs" element={<PageNotFound />} />
        <Route
          path="/how-it-works"
          element={
            <HowItWork
              title="How it works"
              className="bottomBg--pattern rightBg--pattern"
            />
          }
        />
        <Route
          path="/performance"
          element={
            <Performance
              title="Performance"
              className="bottomBg--pattern leftBg--pattern"
            />
          }
        />
        <Route
          path="/portfolio"
          element={
            <Portfolio
              title="Portfolio"
              className="bottomBg--pattern leftBg--pattern"
            />
          }
        />
        <Route
          path="/press"
          element={
            <Press
              title="Press and Appearances"
              className="bottomBg--pattern leftBg--pattern"
            />
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <PrivacyPolicy
              title="Privacy Policy"
              className="bottomBg--pattern leftBg--pattern"
            />
          }
        />
        <Route
          path="/request-invite"
          element={
            <Request
              title="Request"
              className="bottomBg--pattern leftBg--pattern"
            />
          }
        />
        <Route path="/sorry" element={<Sorry title="Sorry" />} />
        <Route
          path="/strategy"
          element={
            <Strategy
              title="Strategy"
              className="bottomBg--pattern leftBg--pattern"
            />
          }
        />
        <Route path="/thank-you" element={<ThankYou title="Thank you" />} />
        <Route
          path="/register-appointment"
          element={<Register title="Register Appointment" />}
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

function PageNotFound() {
  return (
    <div className="notFound">
      <h5>404 Page not found</h5>
    </div>
  );
}

export default App;
