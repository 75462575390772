import { useHistory, useNavigate } from "react-router-dom";
import Banner from "components/Banner";
import Template from "./Template";
import GoRequest from "components/GoRequest";
import Review from "components/Review";
import Img from "components/Img";
import { useState } from "react";
import { setCookie } from "utils/calendly";

export default ({ title, className = "" }) => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isRich, setRich] = useState(null);

  const navigate = useNavigate();

  const onSubmitRequest = (e) => {
    e.preventDefault();

    setCookie("valFn", fname);
    setCookie("valLn", lname);
    setCookie("valEm", email);
    setCookie("valPh", phone && phone.length == 10 ? "1" + phone : "");
    setCookie("richEnough", isRich);

    if (isRich) {
      navigate("/register-appointment");
    }
  };
  return (
    <Template title={title} className={className}>
      <Banner
        className="requestBanner"
        banner={require("assets/images/requestBanner.jpg")}
        titleClass="upper-case"
        title={
          <>
            Let’s <span className="blueClr"> Do This.</span>
          </>
        }
      >
        <h5 className="regular">
          We’re excited you’re interested in learning more about{" "}
          <span className="blueClr">Techvestor!</span> We help you build cash
          flow and lower your taxes with short term rentals.{" "}
        </h5>
        <p>
          We leverage a ton of technology (hence the name) and work with people
          from all industries. Fill this out, book your call and we’ll be in
          touch!{" "}
        </p>
      </Banner>
      <>
        <section className="requestSection">
          <div className="container">
            <div className="title__wrapper">
              <h2
                className="medium blueClr animated fdeIn__Up"
                data-emergence="visible"
              >
                Step 1:
              </h2>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="requestStep">
                  <h3>Are you an accredited investor?</h3>
                </div>
              </div>
              <div className="col-md-6">
                <div className="requestStepContent">
                  <p>
                    <strong className="blueClr">Why We Ask:</strong> As a
                    regulated investment platform, we must learn more about you
                    before you request an invite to our investment
                    opportunities.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 no-desktop">
                <div className="requestStep">
                  <h3>
                    Generally, you are an accredited investor if any of the
                    following are true:{" "}
                  </h3>
                  <div className="requestStepContent">
                    <ul className="list-style-none">
                      <li>
                        Annual income greater than $200k (for the last 2 years)
                      </li>
                      <li>
                        Joint household income greater than $300k (for the last
                        2 years)
                      </li>
                      <li>
                        Net worth greater than $1M (excluding your primary
                        residence, either individually or jointly with your
                        spouse)
                      </li>
                      <li>
                        You invest on behalf of an entity with at least $5M in
                        assets or a business which all the equity owners are
                        accredited investors.
                      </li>
                    </ul>
                    <p>(per the full SEC definition)</p>
                  </div>
                  <form
                    action="/register-appointment"
                    className="requestStep__form"
                    onSubmit={onSubmitRequest}
                  >
                    <div className="row">
                      <div className="col-md-12 col-lg-6 col-xxl-5">
                        <div className="form-group">
                          <input
                            type="radio"
                            defaultChecked={isRich}
                            id="yes"
                            name="radio-group"
                            onClick={() => setRich(true)}
                          />
                          <label className="radiolabel" htmlFor="yes">
                            Yes, I’m accredited
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group">
                          <input
                            type="radio"
                            defaultChecked={isRich == false}
                            id="no"
                            name="radio-group"
                            onClick={() => setRich(false)}
                          />
                          <label className="radiolabel" htmlFor="no">
                            No, I’m not accredited
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>First Name*</label>
                          <input
                            type="text"
                            name="first_name"
                            className="field__input"
                            required
                            value={fname}
                            onChange={(e) => setFname(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Last Name*</label>
                          <input
                            type="text"
                            name="last_name"
                            className="field__input"
                            required
                            value={lname}
                            onChange={(e) => setLname(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Email Address*</label>
                          <input
                            type="email"
                            name="email"
                            className="field__input"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Phone Number*</label>
                          <input
                            type="text"
                            name="phone"
                            className="field__input"
                            required
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group form-group-btn">
                          <button className="commonBtn full_Width blueBg whiteClr">
                            Continue{" "}
                            <Img src={require("assets/images/svg/arrow.svg")} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-6 no-mobile">
                <div className="requestStepContent">
                  <ul className="list-style-none">
                    <li>
                      Annual income greater than $200k (for the last 2 years)
                    </li>
                    <li>
                      Joint household income greater than $300k (for the last 2
                      years)
                    </li>
                    <li>
                      Net worth greater than $1M (excluding your primary
                      residence, either individually or jointly with your
                      spouse)
                    </li>
                    <li>
                      You invest on behalf of an entity with at least $5M in
                      assets or a business which all the equity owners are
                      accredited investors.
                    </li>
                  </ul>
                  <p>(per the full SEC definition)</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <Review className="clientSection" />
      <GoRequest img={require(`assets/images/requestBtm-img.jpg`)} />
    </Template>
  );
};
