import { Link } from "react-router-dom";
import Banner from "components/Banner";
import Template from "./Template";
import GoRequest from "components/GoRequest";
import Review from "components/Review";

export default ({ title, className = "" }) => {
  return (
    <Template title={title} className={className}>
      <Banner
        className="homeBanner aboutBanner"
        align="start"
        banner={require("assets/images/sorry.jpg")}
        title={<>Sorry, we’re only accepting accredited investors at this time.</>}
      >
        <div className="bannerSection__leftmdm">
          <h3 className="medium">
            As a regulated investment platform, we’re required to learn more about you before you request an invite to our investment opportunities.
          </h3>
        </div>
      </Banner>
      <>
        <section className="sorryDeffination dottedSectionBg">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="sorryDeffination__head animated fdeIn__Up" data-emergence="visible">
                  <p>Generally, you are an accredited investor if any of the following are true:</p>
                </div>
                <div className="sorryDeffination__text animated fdeIn__Up" data-emergence="visible">
                  <p>- Annual income greater than $200k (for the last 2 years) Joint household income greater than $300k (for the last 2 years)</p>
                  <p>- Net worth greater than $1M (excluding your primary residence, either individually or jointly with your spouse)</p>
                  <p>
                    - You invest on behalf of an entity with at least $5M in assets or a business which all the equity owners are accredited
                    investors.
                  </p>
                </div>
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="sorryDeffination__btm animated fdeIn__Up" data-emergence="visible">
                  <h5>(per the full SEC definition)</h5>
                  <p>
                    If you think this was a mistake, please <Link to="/javascript:void(0)">click here</Link> to resubmit your application.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <Review />
      <GoRequest img={require(`assets/images/sorry1.jpg`)} />
    </Template>
  );
};
