import Img from "./Img";

export default ({ className = "", align = "center", titleClass = "", title = "", banner = "", children }) => {
  return (
    <section className={`bannerSetion ${className}`}>
      <div className="container-fluid">
        <div className={`bannerSetion__row d-flex align-items-${align}`}>
          <div className="bannerSetion__left">
            <div className="bannerSetion__content animated fdeIn__Up" data-emergence="visible">
              <h1 className={`commonHeading ${titleClass}`}>{title}</h1>
              {children}
            </div>
          </div>
          <div className="bannerSetion__right">
            <div className="bannerSetion__imgBox roundedCorner__left animated fdeIn__Up" data-emergence="visible">
              <Img className="ukiyo" src={banner} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
