import Img from "./Img";

export default ({ title = "Techvestors work at:", className = "aboutReview" }) => {
  return (
    <>
      <section className={`reviewSection ${className}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 textColumn">
              <div className="reviewSection__text ">
                <h6 className="blueClr m-0">{title}</h6>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 sliderColumn">
              <div className="reviewSection__clients client-slider">
                <div className="client-slider--item">
                  <Img src={require("assets/images/svg/dropbox-logo.svg")} />
                </div>
                <div className="client-slider--item">
                  <Img src={require("assets/images/svg/uber-logo.svg")} />
                </div>
                <div className="client-slider--item">
                  <Img src={require("assets/images/svg/tesla-logo.svg")} />
                </div>
                <div className="client-slider--item">
                  <Img src={require("assets/images/svg/tikTok-logo.svg")} />
                </div>
                <div className="client-slider--item">
                  <Img src={require("assets/images/svg/uber-logo.svg")} />
                </div>
                <div className="client-slider--item">
                  <Img src={require("assets/images/svg/tesla-logo.svg")} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
