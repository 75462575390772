import { Link } from "react-router-dom";
import Banner from "components/Banner";
import Template from "./Template";
import GoRequest from "components/GoRequest";
import Review from "components/Review";
import { useEffect, useState } from "react";
import { getArticles, getPodcasts } from "utils/api";
import $ from "jquery";
import Img from "components/Img";
import moment from "moment";

const Podcast = ({
  date,
  img,
  podcast,
  episode,
  youtube,
  google,
  apple,
  url,
  mp3,
}) => {
  const onClickListen = () => {
    $(".j-podcast-audio").remove();
    $(document.body).append(
      `<audio class="j-podcast-audio" src="${mp3}" controls autoplay></audio>`
    );
  };
  return (
    <>
      <div className="pressdata__hldr item posdcast-list">
        <div className="container">
          <div className="presshdr__inner">
            <div className="pressdata__item">
              <div
                className="pressdata__itemLft animated fdeIn__left"
                data-emergence="visible"
              >
                <span className="pressdata__poddate">
                  {moment.unix(date).format("MMMM D, YYYY")}
                </span>
                <div className="pressdata__itemLftInr">
                  <Img src={img} />
                </div>
              </div>
              <div
                className="pressdata__itemRyt animated fdeIn__Up"
                data-emergence="visible"
              >
                <span className="pressdata__podtype">Podcast</span>
                <div className="itemRytHead">
                  <h3>
                    <Link target="_blank" to={url || "#"}>
                      {podcast}
                    </Link>
                  </h3>
                </div>
                <div className="itemRytDiscrip">
                  <p>{episode}</p>
                </div>
                <div className="itemRytPodtxt">
                  <div className="itemRytPodtxt__btnone">
                    <Link
                      onClick={onClickListen}
                      className="commonBtn commonBoxshadow blueBg"
                    >
                      <span>
                        {" "}
                        <Img
                          src={require("assets/images/svg/pod-one-btn.svg")}
                        />{" "}
                      </span>
                      <span>Listen Now</span>
                    </Link>
                  </div>
                  <div className="itemRytPodtxt__btnmulti">
                    <span className="podcstlcn">Listen on</span>
                    <div className="podcstIcon__listOuter">
                      <div className="podcstIcon__list">
                        {apple && (
                          <Link
                            to={apple}
                            target="_blank"
                            className="podcstIcon__list--item podcstIcon"
                          >
                            <Img
                              src={require("assets/images/svg/Group-2053.svg")}
                            />
                          </Link>
                        )}
                        {google && (
                          <Link
                            to={google}
                            target="_blank"
                            className="podcstIcon__list--item podcstdoticn"
                          >
                            <Img
                              src={require("assets/images/svg/Group-2054.svg")}
                            />
                          </Link>
                        )}
                        {youtube && (
                          <Link
                            to={youtube}
                            target="_blank"
                            className="podcstIcon__list--item podcstYoutb"
                          >
                            <Img
                              src={require("assets/images/svg/youtube_icon.svg")}
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Article = ({ date, img, url, publication, title, summary }) => {
  return (
    <>
      <div className="pressdata__hldr item article-list">
        <div className="container">
          <div className="presshdr__inner">
            <div className="pressdata__item">
              <div
                className="pressdata__itemLft animated fdeIn__left"
                data-emergence="visible"
              >
                <span className="pressdata__poddate">{date}</span>
                <div className="pressdata__itemLftInr">
                  <Img src={img} />
                </div>
              </div>
              <div
                className="pressdata__itemRyt animated fdeIn__Up"
                data-emergence="visible"
              >
                <span className="pressdata__podtype">Article</span>
                <div className="itemRytHead">
                  <h3>{title}</h3>
                </div>
                <div className="itemRytDiscrip">
                  <p>{summary}…</p>
                </div>
                <div className="itemRytPodtxt podcstlcnArtcl">
                  <div className="itemRytPodtxt__btnone">
                    <Link to={url} target="_blank" className="commonBtn blueBg">
                      <span>Read Now</span>
                    </Link>
                  </div>
                  <div className="itemRytPodtxt__btnmulti">
                    <span className="podcstlcn">
                      Featured in: {publication}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ({ title, className = "" }) => {
  const [showPodcasts, setShowPodcasts] = useState(true);
  const [showArticles, setShowArticles] = useState(true);

  const [podcasts, setPodcasts] = useState([]);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    getPodcasts().then((items) => {
      setPodcasts(items);
    });
    getArticles().then((items) => {
      setArticles(items);
    });
    return () => {
      $(".j-podcast-audio").remove();
    };
  }, []);

  useEffect(() => {
    if (!showPodcasts) {
      $(".j-podcast-audio").remove();
    }
  }, [showPodcasts]);

  const onClickAll = () => {
    setShowPodcasts(true);
    setShowArticles(true);
  };
  const onClickPodcasts = () => {
    setShowPodcasts(true);
    setShowArticles(false);
  };
  const onClickArticles = () => {
    setShowPodcasts(false);
    setShowArticles(true);
  };

  return (
    <Template title={title} className={className}>
      <section className="presshdr">
        <div className="container">
          <div className="presshdr__txt">
            <h2 className="h1 animated fdeIn__Up" data-emergence="visible">
              Press and Appearances
            </h2>
          </div>
        </div>
      </section>

      <section className="presstabs">
        <div className="container">
          <div className="presstabs__btns">
            <ul id="filterOptions">
              <li className={showPodcasts && showArticles ? "active" : ""}>
                <Link className="all" onClick={onClickAll}>
                  All
                </Link>
              </li>
              <li className={showPodcasts && !showArticles ? "active" : ""}>
                <Link className={`posdcast-list`} onClick={onClickPodcasts}>
                  PODCAsts
                </Link>
              </li>
              <li className={showArticles && !showPodcasts ? "active" : ""}>
                <Link className={`article-list`} onClick={onClickArticles}>
                  FEATURES
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="pressdata">
        {showPodcasts && (
          <>
            {podcasts
              .sort((a, b) => b.date - a.date)
              .map((podcast, id) => (
                <Podcast key={id} {...podcast} />
              ))}
          </>
        )}
        {showArticles && (
          <>
            {articles
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .map((article, id) => (
                <Article key={id} {...article} />
              ))}
          </>
        )}
      </section>

      <Review />
      <GoRequest img={require(`assets/images/pod-btm.jpg`)} />
    </Template>
  );
};
