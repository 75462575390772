import { Link } from "react-router-dom";
import Banner from "components/Banner";
import Template from "./Template";
import GoRequest from "components/GoRequest";
import Review from "components/Review";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { getProperties } from "utils/api";
import Img from "components/Img";

const Property = ({
  title = "",
  location = "",
  url = "",
  revenue = 52000,
  images = [],
}) => {
  return (
    <>
      <div className="col-lg-6 col-md-6 ">
        <div
          className="portfolioItems__cards commonBoxshadow animated fdeIn__Up"
          data-emergence="visible"
        >
          <div className="portfolioItems__sliderHldr">
            {images.map((image, id) => (
              <div key={id} className="portfolioItems__cardsImg">
                <Img src={image} />
              </div>
            ))}
          </div>
          <div className="portfolioItems__cardstxt">
            <div className="portfolioItems__cardstxthd">
              <h5 className="bold">
                <span>{title}</span>
              </h5>
            </div>
            <div className="portfolioItems__cardstxdis">
              <p>{location}</p>
            </div>
            <div className="portfolioItems__cardstxtrvnu d-flex justify-content-between">
              <span className="blueClr bold">
                Projected Revenue: <br />${Number(revenue).toLocaleString()}+
              </span>
              <Link
                to="https://www.airbnb.com/users/2672622/listings"
                className="commonBtn blackBg d-flex align-items-center justify-content-center"
                target="_blank"
              >
                <span>More details</span>
                <Img src={require("assets/images/svg/arrow.svg")} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ({ title, className = "" }) => {
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    getProperties().then((props) => {
      setProperties(props.slice(0, 10));
    });
  }, []);

  useLayoutEffect(() => {
    if (window.portfolioSlickInit) {
      window.portfolioSlickInit();
    }
  }, [properties]);

  return (
    <Template title={title} className={className}>
      <Banner
        className="portfolioBanner"
        banner={require("assets/images/OUR-PROPERTY.jpg")}
        titleClass="upper-case"
        title={
          <>
            OUR
            <span className="blueClr"> PROPERTY </span>
            PORTFOLIO
          </>
        }
      >
        <div className="bannerSection__leftmdm">
          <h3 className="regular">
            Take a look around at some of our{" "}
            <span className="blueClr">70+ properties!</span>
          </h3>
        </div>
        <p>
          As a reminder, these numbers do not represent an offer to buy or sell
          securities and past performance nor current performance is an
          indicator of future performance. Please make investment decisions that
          you are informed on, qualified for and understand the risks.
        </p>
        <div className="btn__wrapper d-flex align-items-center">
          <Link
            to="/request-invite"
            className="commonBtn blackBg d-flex align-items-center justify-content-center"
          >
            <span>Request Invite</span>
            <Img src={require("assets/images/svg/arrow.svg")} />
          </Link>
        </div>
      </Banner>
      <>
        <section className="mapHolder mapsvgbg">
          <div className="container">
            <div
              className="mapHolder__head  animated fdeIn__Up"
              data-emergence="visible"
            >
              <h1 className="commonHeading upper-case">
                OUR
                <span className="blueClr">
                  PROPERTY <br />
                </span>
                Locations
              </h1>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="mapHolder__img animated fdeIn__Up"
                  data-emergence="visible"
                >
                  <Img
                    src={require("assets/images/svg/New_Map_Portfolio.svg")}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="portfolioItems">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolioItems__head">
                  <h4 className="regular">TOTAL LIVE PROPERTIES: 72</h4>
                </div>
              </div>
            </div>

            <div className="row portfolioItems__row">
              {properties.map((property, id) => (
                <Property key={id} {...property} />
              ))}
            </div>

            <div className=" row portfolioItems__loadMore">
              <div className="portfolioItems__readMore">
                <Link
                  to="/request-invite"
                  className="commonBtn blueBg d-flex align-items-center text-center justify-content-center"
                >
                  <span>View More</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.823"
                    height="24.304"
                    viewBox="0 0 17.823 24.304"
                  >
                    <g id="lock" transform="translate(-5 -1)">
                      <rect
                        id="Rectangle_341"
                        data-name="Rectangle 341"
                        width="16"
                        height="11"
                        rx="2"
                        transform="translate(6 13)"
                      />
                      <path
                        id="Path_9309"
                        data-name="Path 9309"
                        d="M1.62,0A1.62,1.62,0,1,1,0,1.62,1.62,1.62,0,0,1,1.62,0Z"
                        transform="translate(12.291 17.203)"
                      />
                      <path
                        id="Path_9307"
                        data-name="Path 9307"
                        d="M20.393,12.342V7.481a6.481,6.481,0,1,0-12.962,0v4.861A2.43,2.43,0,0,0,5,14.773v8.1A2.43,2.43,0,0,0,7.43,25.3H20.393a2.43,2.43,0,0,0,2.43-2.43v-8.1A2.43,2.43,0,0,0,20.393,12.342ZM9.051,7.481a4.861,4.861,0,1,1,9.722,0v4.861H9.051ZM21.2,22.874a.81.81,0,0,1-.81.81H7.43a.81.81,0,0,1-.81-.81v-8.1a.81.81,0,0,1,.81-.81H20.393a.81.81,0,0,1,.81.81Z"
                        transform="translate(0)"
                      />
                      <path
                        id="Path_9308"
                        data-name="Path 9308"
                        d="M15.43,20a2.43,2.43,0,1,0,2.43,2.43A2.43,2.43,0,0,0,15.43,20Zm0,3.241a.81.81,0,1,1,.81-.81A.81.81,0,0,1,15.43,23.241Z"
                        transform="translate(-1.519 -3.607)"
                      />
                    </g>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>

      <Review />
      <GoRequest img={require(`assets/images/Cash-Flow.-Your-Way-por.jpg`)} />
    </Template>
  );
};
