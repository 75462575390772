import { Link } from "react-router-dom";

export default ({
  title = "Cash Flow. Your Way.",
  text,
  img = require(`assets/images/pod-btm.jpg`),
  seeData = false,
}) => {
  return (
    <section className="commonBtmSection aboutCommon ">
      <div className="container ">
        <div
          className="commonBtmSection__wrapper commonBoxshadow "
          style={{ backgroundImage: `url(${img})` }}
        >
          <div className="commonBtmSection__cntnt ">
            <div
              className="commonHeading whiteClr animated fdeIn__Up"
              data-emergence="visible "
            >
              {title}
            </div>
            {text && (
              <p className="animated fdeIn__Up" data-emergence="visible">
                {text}
              </p>
            )}
          </div>
          <div className="btn__wrapper d-flex align-items-center ">
            {seeData && (
              <Link
                to="/data"
                className="commonBtn whiteBg blackClr d-flex align-items-center justify-content-center"
              >
                <span>See the Data</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg "
                  width="22.907 "
                  height="17.147 "
                  viewBox="0 0 22.907 17.147 "
                >
                  <path
                    data-name="Path 1 "
                    d="M8.221,0,0,9.1l.6.542L7.816,1.653V22.284h.808V1.653l7.217,7.985.6-.542Z "
                    transform="translate(22.534 0.353) rotate(90) "
                    strokeWidth="0.5 "
                  />
                </svg>
              </Link>
            )}
            <Link
              to="/request-invite"
              className="commonBtn whiteBg blackClr d-flex align-items-center justify-content-center"
            >
              <span>Request Invite</span>
              <svg
                xmlns="http://www.w3.org/2000/svg "
                width="22.907 "
                height="17.147 "
                viewBox="0 0 22.907 17.147 "
              >
                <path
                  data-name="Path 1 "
                  d="M8.221,0,0,9.1l.6.542L7.816,1.653V22.284h.808V1.653l7.217,7.985.6-.542Z "
                  transform="translate(22.534 0.353) rotate(90) "
                  strokeWidth="0.5 "
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
