import { Link } from "react-router-dom";
import Banner from "components/Banner";
import Template from "./Template";
import GoRequest from "components/GoRequest";
import Review from "components/Review";
import { useCallback, useEffect, useState } from "react";
import { getGSheet } from "utils/api";
import Img from "components/Img";

export default ({ title, className = "" }) => {
  const [gsheet, setGsheet] = useState([]);
  useEffect(() => {
    getGSheet().then((data) => {
      setGsheet(data);
    });
  }, []);

  const GSheetValue = useCallback(
    (cellId = "A1") => {
      const cellCol = cellId.toUpperCase().charCodeAt(0) - "A".charCodeAt(0);
      const cellRow = Number(cellId.substring(1)) - 1;
      return gsheet && gsheet[cellRow] && gsheet[cellCol]
        ? gsheet[cellRow][cellCol]
        : "";
    },
    [gsheet]
  );

  const toNumber = (data) => data.replace(/\D/g, "");

  return (
    <Template title={title} className={className}>
      <Banner
        className="strategypageBanner performancepageBanner"
        banner={require("assets/images/performanceBanner.jpg")}
        title={<>Performance</>}
      >
        <Link
          to="/request-invite"
          className="commonBtn blackBg d-flex align-items-center justify-content-center"
        >
          <span>Request invite</span>
          <Img src={require("assets/images/svg/arrow.svg")} />
        </Link>
        <div className="dataArea">
          <div className="dataArea__box">
            <div className="dataCount textGradient">
              <span className="counter" data-to="40"></span>%
            </div>
            <div className="dataCntnt">
              <p>
                Lower costs to operate <br /> because of economies <br /> of
                scale + tech{" "}
              </p>
            </div>
          </div>
          <div className="dataArea__box boxPosition">
            <div className="dataCount textGradient">
              <span className="counter" data-to="20"></span>%
            </div>
            <div className="dataCntnt">
              <p>
                Higher profits with <br /> functional design + <br /> automation{" "}
              </p>
            </div>
          </div>
        </div>
      </Banner>
      <>
        <section className="portfolioSection">
          <div className="container">
            <div className="portfolioSection__row">
              <div className="portfolioSection__column">
                <div className="portfolioSection__column--inner d-flex flex-column justify-content-center ps-0 portfolio--head">
                  <div
                    className="commonHeading blackClr animated fdeIn__Up"
                    data-emergence="visible"
                  >
                    Portfolio
                    <span className="blueClr"> Overview</span>
                  </div>
                </div>
              </div>
              {[3, 9, 10, 11, 21, 4, 5].map((rowID, id) => (
                <div key={id} className="portfolioSection__column">
                  <div className="portfolioSection__column--inner d-flex flex-column justify-content-center leftBorder">
                    <div className="portfolio__numbers">
                      <div className="h1 blueClr">
                        {GSheetValue(`B${rowID}`)}
                      </div>
                    </div>
                    <div className="portfolio__cntnt">
                      <h3
                        className="blueClr animated fdeIn__leftBig"
                        data-emergence="visible"
                      >
                        {GSheetValue(`A${rowID}`)}
                      </h3>
                      <p>{GSheetValue(`D${rowID}`)}</p>
                    </div>
                  </div>
                </div>
              ))}
              <div className="portfolioSection__column">
                <div className="portfolioSection__column--inner ps-0 d-flex flex-column justify-content-center">
                  <Link
                    to="/request-invite"
                    className="commonBtn blueBg d-flex align-items-center justify-content-center"
                  >
                    <span>Request invite</span>
                    <Img src={require("assets/images/svg/arrow.svg")} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="lastweekSection">
          <div className="container">
            <div className="title__wrapper">
              <div
                className="commonHeading blackClr animated fdeIn__Up"
                data-emergence="visible"
              >
                Last Week <br /> at
                <span className="blueClr"> Techvestor</span>
              </div>
            </div>
          </div>
          <div className="wideSection wideCommonstyle wideSectionReverse lastweekWide">
            <div className="container-fluid">
              <div className="wideSection__row d-flex align-items-center justify-content-between">
                <div
                  className="wideSection__img roundedCorner__right commonBoxshadow "
                  data-emergence="visible"
                >
                  <Img src={require("assets/images/performanceImg-2.jpg")} />
                </div>
                <div className="wideSection__content leftblueBordr">
                  <div className="h1 blackClr animated">
                    ${" "}
                    <span
                      className="counter"
                      data-to={toNumber(GSheetValue(`B6`))}
                    ></span>
                  </div>
                  <div
                    className="commonHeading blueClr animated fdeIn__leftBig"
                    data-emergence="visible"
                  >
                    {GSheetValue(`A6`) + "  "}
                    <div
                      class="tooltip-mobile"
                      data-tooltip={GSheetValue(`D6`)}
                    >
                      <i
                        class="fa fa-info-circle fa-2xs"
                        focusable="false"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wideSection wideCommonstyle lastweekWide">
            <div className="container-fluid">
              <div className="wideSection__row d-flex align-items-center justify-content-between">
                <div
                  className="wideSection__img roundedCorner__right commonBoxshadow "
                  data-emergence="visible"
                >
                  <Img src={require("assets/images/performanceImg-3.jpg")} />
                </div>
                <div className="wideSection__content leftblueBordr">
                  <div className="h1 blackClr animated">
                    <span
                      className="counter"
                      data-to={toNumber(GSheetValue(`B7`))}
                    ></span>
                  </div>
                  <div
                    className="commonHeading blueClr animated fdeIn__leftBig"
                    data-emergence="visible"
                  >
                    {GSheetValue(`A7`) + "  "}
                    <div
                      class="tooltip-left tooltip-mobile"
                      data-tooltip={GSheetValue(`D7`)}
                    >
                      <i
                        class="fa fa-info-circle fa-2xs"
                        focusable="false"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wideSection wideCommonstyle wideSectionReverse lastweekWide">
            <div className="container-fluid">
              <div className="wideSection__row d-flex align-items-center justify-content-between">
                <div
                  className="wideSection__img roundedCorner__right commonBoxshadow "
                  data-emergence="visible"
                >
                  <Img src={require("assets/images/performanceImg-4.jpg")} />
                </div>
                <div className="wideSection__content leftblueBordr">
                  <div className="h1 blackClr animated">
                    ${" "}
                    <span
                      className="counter"
                      data-to={toNumber(GSheetValue(`B8`))}
                    ></span>
                  </div>
                  <div
                    className="commonHeading blueClr animated fdeIn__leftBig"
                    data-emergence="visible"
                  >
                    {GSheetValue(`A8`) + "  "}
                    <div
                      class="tooltip-mobile"
                      data-tooltip={GSheetValue(`D8`)}
                    >
                      <i
                        class="fa fa-info-circle fa-2xs"
                        focusable="false"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="monthlyPerformance">
          <div className="container">
            <div className="title__wrapper">
              <div
                className="commonHeading blackClr animated fdeIn__Up"
                data-emergence="visible"
              >
                <span className="blueClr"> February</span>, at <br /> a Glance
              </div>
            </div>
            <div className="monthlyPerformance__listing">
              {[12, 13, 14, 16].map((rowID, id) => (
                <div key={id} className="monthlyPerformance__listing--item">
                  <div className="item--row d-flex align-items-center justify-content-between">
                    <div className="number">
                      <div className="h1 blueClr">
                        {GSheetValue(`B${rowID}`)}
                      </div>
                    </div>
                    <div className="title">
                      <h3 className="blueClr">{GSheetValue(`A${rowID}`)}</h3>
                    </div>
                    <div className="content">
                      <p>{GSheetValue(`D${rowID}`)}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="adrSection">
          <div className="container">
            <div className="adrSection__wrapper">
              <div className="adrSection__row">
                <div className="adrSection__column">
                  <div className="adrSection__column--inner d-flex flex-column justify-content-center ps-0 adr--head">
                    <div
                      className="commonHeading whiteClr upper-case animated fdeIn__Up"
                      data-emergence="visible"
                    >
                      ADR & OCC
                    </div>
                  </div>
                </div>
                {[17, 18, 19, 20].map((rowID, id) => (
                  <div className="adrSection__column">
                    <div className="adrSection__column--inner d-flex flex-column justify-content-center leftblueBordr">
                      <div className="adr--number">
                        <div className="h1 whiteClr">
                          {GSheetValue(`B${rowID}`)}
                        </div>
                      </div>
                      <div className="adr--cntnt whiteClr">
                        <h3
                          className="animated fdeIn__leftBig"
                          data-emergence="visible"
                        >
                          {GSheetValue(`A${rowID}`)}
                        </h3>
                        <p>{GSheetValue(`D${rowID}`)}</p>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="adrSection__column">
                  <div className="adrSection__column--inner d-flex flex-column justify-content-center">
                    <Link
                      to="/request-invite"
                      className="commonBtn blueBg d-flex align-items-center justify-content-center"
                    >
                      <span>Request invite</span>
                      <Img src={require("assets/images/svg/arrow.svg")} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="wideSection wideCommonstyle wideSectionReverse disclaimer">
          <div className="container-fluid">
            <div className="wideSection__row d-flex align-items-center justify-content-between position-relative">
              <div
                className="wideSection__img roundedCorner__right commonBoxshadow "
                data-emergence="visible"
              >
                <Img src={require("assets/images/performanceImg-5.jpg")} />
              </div>
              <div className="wideSection__content">
                <div
                  className="commonHeading blackClr upper-case animated fdeIn__Up"
                  data-emergence="visible"
                >
                  {GSheetValue(`A25`)}:
                </div>
                <p>{GSheetValue("B25")}</p>
                <div className="lastUpdated">
                  <h3>
                    Last Updated: <br />
                    <span className="blueClr">{GSheetValue("B22")}</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <Review className="clientSection" />
      <GoRequest img={require(`assets/images/performanceBtm-img.jpg`)} />
    </Template>
  );
};
