import { Link } from "react-router-dom";
import Banner from "components/Banner";
import Template from "./Template";
import GoRequest from "components/GoRequest";
import Review from "components/Review";
import Img from "components/Img";
import { useEffect } from "react";

export default ({ title, className = "" }) => {
  useEffect(() => {
    if (window.gtag) {
      window.gtag("event", "conversion", { send_to: "AW-10811169445/8uldCNPZm4EYEKW1laMo" });
    }
  }, []);
  return (
    <Template title={title} className={className}>
      <Banner
        className="aboutBanner thankYou"
        banner={require("assets/images/thankyou-banner.jpg")}
        align="start"
        titleClass="upper-case"
        title={
          <>
            Thank <br />
            you for <span className="blueClr"> registering!</span>
          </>
        }
      >
        <div className="thankYou__txt">
          <h5 className="regular">
            Thank you for registering for an intro call with us! Check your inbox for your calendar invite and confirmation, and we look forward to
            chatting with you.
          </h5>
        </div>
        <p>
          If you have any questions in the interim, feel free to <br />
          <Link to="/contact-us" className="blueClr bold">
            <u>get in touch with us!</u>
          </Link>
        </p>
      </Banner>
      <>
        <section className="cashThankyou dottedSectionBg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="cashThankyou__head animated fdeIn__Up" data-emergence="visible">
                  <h1 className="">“Cash flow & Real Estate changed our lives from the start. We just didn’t realize it until a decade later.”</h1>
                  <h3 className="regular">— S&S aka Sabrina & Sief</h3>
                </div>
              </div>
            </div>
            <div className="row ourTeam__hldr flex-row-reverse">
              <div className="ourTeam__item">
                <div className="ourTeam__list animated" data-emergence="visible">
                  <div className="ourTeam__imghldr commonBoxshadow">
                    <Img src={require("assets/images/sief.jpg")} />
                  </div>
                  <div className="ourTeam__content">
                    <span className="ourTeam__name">Sief</span>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="ourTeam__item">
                <div className="ourTeam__list animated" data-emergence="visible">
                  <div className="ourTeam__imghldr commonBoxshadow">
                    <Img src={require("assets/images/Sabrina_2022.jpg")} />
                  </div>
                  <div className="ourTeam__content">
                    <span className="ourTeam__name">Sabrina</span>
                  </div>
                </div>
              </div>
              {/*  */}
            </div>
          </div>
        </section>
        {/* cashThankyou End */}
        <section className="ourStory ourStorythankyou">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="ourStory__lft animated fdeIn__Up" data-emergence="visible">
                  <h3>Our Story</h3>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="ourStory__ryt animated fdeIn__Up" data-emergence="visible">
                  <p>
                    We’ve been investing in real estate since 2009. Doing everything from private lending, flipping and long term hold. The journey to
                    cash flow and growth was hard. Incredibly time consuming, lots of mistakes and over a decade of memories, both good and bad.
                    Around that same time, a small little company was beginning that had a wild vision of allowing a “stranger” to stay the night at
                    your place. That company was Airbnb. Little did we know it but our journey would lead us to STRs (short term rentals) as one of
                    the biggest reasons we were able to break free from the 9-5 from places like Facebook & Apple.
                  </p>
                  <p>
                    10 years later and we’ve learned that having short term rentals in your portfolio for the cash flow can be a powerful wealth
                    building strategy. The process of doing one was shitty. Lenders. Brokers. Management. All headaches.
                  </p>
                  <p>
                    So, we built a better mouse trap. One that was simple, passive and partnership driven with aligned incentives from day one. Cash
                    flow from day one. Transparency from day one. And tax benefits from day one.
                  </p>
                  <p>
                    We’re focusing helping others accomplish what we’ve been able to do and to date, we’ve helped hundreds get started or continue
                    their REI journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Our Story End */}
        <section className="ourVision">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ourVision__head animated fdeIn__Up" data-emergence="visible">
                  <h3>Our Vision</h3>
                </div>
              </div>
            </div>
            <div className="row ourVision__hldr animated fdeIn__Up" data-emergence="visible">
              <div className=" col-lg-4 ourVision__crd ourVision__crd--hover">
                <div className="blackCard  blackCard--hover blackBg commonBoxshadow">
                  <div className="blackCard__inner d-flex flex-column">
                    <div className="blackCard__img">
                      <Img src={require("assets/images/svg/T-blue-small.svg")} />
                    </div>
                    <div className="blackCard__hdng">
                      <h3 className="blueClr">“Freedom” – Whatever that means to you.</h3>
                    </div>
                    <div className="blackCard__cntnt">
                      <p>
                        Maybe it’s to break away from the 9-5. Maybe it’s to save for college. Maybe it’s to take care of our parents. Whatever your
                        “why” is, cash flow and real estate can help.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className=" col-lg-4 ourVision__crd ">
                <div className="blackCard  blackCard--hover blackBg commonBoxshadow">
                  <div className="blackCard__inner d-flex flex-column">
                    <div className="blackCard__img">
                      <Img src={require("assets/images/svg/T-blue-small.svg")} />
                    </div>
                    <div className="blackCard__hdng">
                      <h3 className="blueClr">Aligned Incentives.</h3>
                    </div>
                    <div className="blackCard__cntnt">
                      <p>
                        No one likes un-necessary fees. Neither do we. You get paid first and foremost. Our partnership is built so that we’ll always
                        have each others best interests.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className=" col-lg-4 ourVision__crd ourVision__crd--hover">
                <div className="blackCard blackBg blackCard--hover commonBoxshadow">
                  <div className="blackCard__inner d-flex flex-column">
                    <div className="blackCard__img">
                      <Img src={require("assets/images/svg/T-blue-small.svg")} />
                    </div>
                    <div className="blackCard__hdng">
                      <h3 className="blueClr">
                        Passive. <br />
                        So you can <br />
                        live your life.
                      </h3>
                    </div>
                    <div className="blackCard__cntnt">
                      <p>
                        Family. Work. Hobbies. Adventures. Life. We don’t have enough time or energy every day as it is. Investments are intended to
                        work for you. We can help.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Our Vision Ends */}
        <section className="parents">
          <div className="container">
            <div className="row parents__inner ">
              <div className="col-lg-5 col-md-12">
                <div className="parents__lft animated fdeIn__Up" data-emergence="visible">
                  <h3>Parents, dog moms, techies and more. REI + Cash Flow is a game changer.</h3>
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="parents__ryt animated fdeIn__Up" data-emergence="visible">
                  <p>
                    No matter who you are, what you do or where you live, REI doesn’t have to feel impossible. Our community comes from all walks of
                    life, from all around the world with one singular goal: to invest for their “why” and each person has a story.
                  </p>
                  <div className="parents__rytbtm">
                    <span> Let’s write yours. </span>
                    <span>Sabrina & Sief</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <Review />
      <GoRequest img={require(`assets/images/thankyou-btm.jpg`)} />
    </Template>
  );
};
