export const setCookie = (cname, cvalue, exdays = 1) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};
export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const getParams = () => {
  const obj = {};
  window.location.search
    .replace("?", "")
    .split("&")
    .forEach((item) => {
      let ary = item.split("=");
      obj[decodeURIComponent(ary[0]).trim()] = ary.length > 1 ? decodeURIComponent(ary[1]).trim() : "";
    });
  return obj;
};

export const getParam = (param) => {
  const params = getParams();
  return params[param] || "";
};

export const resetCookies = () => {
  const utms = { utm_source: "", utm_medium: "website-form", utm_campaign: "", utm_id: "HLB", utm_term: "", utm_content: "" };

  Object.keys(utms).forEach((utm) => {
    if (getParam(utm)) {
      setCookie(utm, getParam(utm) || getCookie(utm) || utms[utm]);
    }
  });
};

export const getCalendlyUrl = () => {
  const cookieKeys = {
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_id: null,
    utm_term: null,
    utm_content: null,
    name: ["valFn", "valLn"],
    email: ["valEm"],
    a1: ["valPh"],
  };
  let str = ``;
  Object.keys(cookieKeys).map((key) => {
    const aryKeys = cookieKeys[key] || [key];
    let strCookie = "";
    aryKeys.forEach((aryKey) => {
      const cookie = getCookie(aryKey);
      if (cookie) {
        strCookie += `${cookie} `;
      }
    });
    strCookie = strCookie.trim();
    if (strCookie) {
      str += `&${key}=${strCookie}`;
    }
  });
  return `https://calendly.com/teamtechvestor/intro-call-with-techvestor?a5=1${str}`;
};
