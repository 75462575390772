import logoImg from "assets/images/svg/logo.svg";
import { Link } from "react-router-dom";
import Img from "./Img";

const Footer = () => {
  return (
    <footer className="footerSection">
      <div className="container">
        <div className="footerSection__wrapper">
          <div className="footerSection__logo">
            <Img src={logoImg} />
          </div>
          <div className="footerSection__nav">
            <div className="footerSection__row d-flex">
              <div className="footerSection__column quickLinks">
                <h6>Quick Links</h6>
                <ul className="list-style-none">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/press">Press & Appearances</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
              <div className="footerSection__column resources">
                <h6>Resources</h6>
                <ul className="list-style-none">
                  <li>
                    <Link to="/how-it-works">How it Works</Link>
                  </li>
                  <li>
                    <Link to="/portfolio">Our Property Portfolio</Link>
                  </li>
                  <li>
                    <Link to="/data">Data</Link>
                  </li>
                  <li>
                    <Link to="/strategy">Strategy</Link>
                  </li>
                </ul>
              </div>
              <div className="footerSection__column getStarted">
                <h6>Get Started</h6>
                <ul className="list-style-none">
                  <li>
                    <Link to="/request-invite">Request Invite</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact</Link>
                  </li>
                </ul>
              </div>
              <div className="footerSection__column reviewBadge">
                <div className="reviewBadge__inner">
                  <iframe
                    src="https://widget.reviews.io/badge-modern/widget?elementId=badge-160&amp;version=1&amp;&amp;store=scoutpads&amp;primaryClr=%236117CD&amp;starsClr=%2333F1C6"
                    width="242"
                    title="Reviews Badge Modern Widget"
                    style={{ minWidth: 200, border: "none" }}
                    height="203"
                    data-lf-yt-playback-inspected-yegkb8lbwoy4ep3z="true"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="copyRight">
              <p>
                ©2021-{new Date().getFullYear()} Techvestor. All rights
                reserved. | Call or Text Us: (415) 650-4705
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
