import axios from "axios";
import { getCookie } from "./calendly";

export const BACKEND_URL = `${process.env.REACT_APP_BASE_URL}/api` || `${window.location.protocol}//${window.location.hostname}/api`;

export const Axios = axios.create({ baseURL: BACKEND_URL });

export const getProperties = async () => {
  return (await Axios.get(`/static_json/portfolio`)).data;
};

export const getArticles = async () => {
  return (await Axios.get(`/static_json/articles`)).data;
};

export const getPodcasts = async () => {
  return (await Axios.get(`/podcasts`)).data;
};

export const getGSheet = async () => {
  return (await Axios.get("/gsheet")).data;
};

export const registerUser = async () => {
  return await Axios.post("/register", {
    fname: getCookie("valFn"),
    lname: getCookie("valLn"),
    email: getCookie("valEm"),
    phone: getCookie("valPh"),
  });
};
