import { Link } from "react-router-dom";
import Banner from "components/Banner";
import Template from "./Template";
import GoRequest from "components/GoRequest";
import Review from "components/Review";
import Img from "components/Img";

export default ({ title, className = "" }) => {
  return (
    <Template title={title} className={className}>
      <Banner
        className="howitworkBanner"
        align="start"
        titleClass="blueClr"
        banner={require("assets/images/howItWork-img-1.jpg")}
        title={<>How it Works</>}
      >
        <h3 className="medium">Assuming it’s a good fit, what happens next?</h3>
        <div className="leftBorder contentSpace howitworkContent">
          <div className="commonHeading blueClr animated fdeIn__leftBig" data-emergence="visible">
            1. Meh, Paperwork.
          </div>
          <h5 className="regular">
            We agree, it’s not the best part. But it’s light, easy and you won’t need to qualify for a loan. You’ll sign a couple key docs to partner
            with us, some legal disclosures and get the process started. This is least fun part we promise.
          </h5>
        </div>
      </Banner>
      <>
        <section className="wideSection wideCommonstyle howitworkWide">
          <div className="container-fluid">
            <div className="wideSection__row d-flex align-items-center justify-content-between">
              <div className="wideSection__img roundedCorner__right commonBoxshadow " data-emergence="visible">
                <Img src={require("assets/images/howItWork-img-2.jpg")} />
              </div>
              <div className="wideSection__content leftBorder contentSpace">
                <div className="commonHeading blueClr animated fdeIn__leftBig" data-emergence="visible">
                  2. Fund your investment.
                </div>
                <p className="h5 regular">
                  Your investment is diversified across our entire portfolio of 35+ properties (and growing) across 8+ states. You can get started
                  with as little as $25,000 and add more each quarter as you get more comfortable.{" "}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="wideSection wideCommonstyle wideSectionReverse howitworkWide">
          <div className="container-fluid">
            <div className="wideSection__row d-flex align-items-center justify-content-between">
              <div className="wideSection__img roundedCorner__right commonBoxshadow " data-emergence="visible">
                <Img src={require("assets/images/howItWork-img-4.jpg")} />
              </div>
              <div className="wideSection__content leftBorder contentSpace">
                <div className="commonHeading blueClr animated fdeIn__leftBig" data-emergence="visible">
                  3. Cash Flow Along the Way <span> 🚀 </span>
                </div>
                <p className="h5 regular">
                  You can expect your first distribution about 3-6 months from the beginning of your investment. We call this the ramp phase where
                  with any good investment, we’re doing the dirty work behind the scenes. Finding, designing, setting up and launching profitable
                  properties.{" "}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="wideSection wideCommonstyle howitworkWide rightBg--pattern">
          <div className="container-fluid">
            <div className="wideSection__row d-flex align-items-center justify-content-between">
              <div className="wideSection__img roundedCorner__right commonBoxshadow " data-emergence="visible">
                <Img src={require("assets/images/howItWork-img-3.jpg")} />
              </div>
              <div className="wideSection__content leftBorder contentSpace">
                <div className="commonHeading blueClr animated fdeIn__leftBig" data-emergence="visible">
                  4. The <br />
                  Big Exit!{" "}
                </div>
                <p className="h5 regular">
                  Once the properties are stabilized, we’ll look to start exiting the portfolio and sell them to the big guys and gals (or on the
                  retail market) for top dollar. All that equity is brought back as “growth equity” and distributed pro rata to you and other
                  investors. And if you liked the experience, let’s go for round 2 (or 3).{" "}
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
      <Review className="clientSection" />
      <GoRequest img={require(`assets/images/howItWork-BTM-IMG.jpg`)} title="Join The Community." />
    </Template>
  );
};
