import { Link } from "react-router-dom";
import Banner from "components/Banner";
import Template from "./Template";
import GoRequest from "components/GoRequest";
import Review from "components/Review";
import { useLayoutEffect } from "react";
import Img from "components/Img";

const strategyCards = [
  {
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="75.034"
        height="75"
        viewBox="0 0 75.034 75"
      >
        <g id="Globe" transform="translate(0.016 0.006)">
          <path
            id="Path_252"
            data-name="Path 252"
            d="M20.031,21.718a2.344,2.344,0,0,1,0-4.687h4.687a2.344,2.344,0,0,0,0-4.687H22.375a2.344,2.344,0,1,0-4.687,0v.422a7.031,7.031,0,0,0,2.344,13.64,2.344,2.344,0,1,1,0,4.687H15.344a2.344,2.344,0,1,0,0,4.687h2.344a2.344,2.344,0,1,0,4.687,0v-.422a7.031,7.031,0,0,0-2.344-13.64Z"
            transform="translate(17.488 13.445)"
          />
          <path
            id="Path_253"
            data-name="Path 253"
            d="M71.924,52.388a37.334,37.334,0,0,0,0-29.764,1.1,1.1,0,0,0-.117-.234A37.5,37.5,0,1,0,12.513,65.466a2.348,2.348,0,1,0,3.14-3.492,32.6,32.6,0,0,1-6.539-8.062H19.59A44.763,44.763,0,0,0,29.855,69.4a32.6,32.6,0,0,1-7.64-2.859,2.344,2.344,0,0,0-2.2,4.125A37.5,37.5,0,0,0,71.806,52.623,1.1,1.1,0,0,0,71.924,52.388ZM65.9,21.1H55.448A44.834,44.834,0,0,0,45.206,5.633,33,33,0,0,1,65.9,21.1ZM29.832,5.633A44.834,44.834,0,0,0,19.59,21.1H9.138A33,33,0,0,1,29.832,5.633ZM6.911,25.788H18.77a2.344,2.344,0,0,0,4.312-.422A40.029,40.029,0,0,1,37.519,5.211a40.1,40.1,0,0,1,14.53,20.2,2.344,2.344,0,0,0,4.219.375H67.986a32.342,32.342,0,0,1,0,23.436H56.268a2.344,2.344,0,0,0-4.312.375A40.1,40.1,0,0,1,37.519,69.8,39.842,39.842,0,0,1,23.035,49.74a2.344,2.344,0,0,0-4.265-.516H7.052a32.108,32.108,0,0,1-.141-23.436ZM45.206,69.38A44.834,44.834,0,0,0,55.448,53.912H65.9A33,33,0,0,1,45.206,69.38Z"
            transform="translate(0)"
          />
          <path
            id="Path_254"
            data-name="Path 254"
            d="M29.436,52.873A23.436,23.436,0,1,1,52.873,29.436,23.436,23.436,0,0,1,29.436,52.873Zm0-42.185A18.749,18.749,0,1,0,48.185,29.436,18.749,18.749,0,0,0,29.436,10.687Z"
            transform="translate(8.083 8.07)"
          />
        </g>
      </svg>
    ),
    title: (
      <>
        Adventurous <br /> Experiences
      </>
    ),
    text: "Markets near national parks, destinations and/or outdoorsy tend to outperform.",
  },
  {
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80.154"
        height="75"
        viewBox="0 0 80.154 75"
      >
        <g id="Loan" transform="translate(-0.005 -1)">
          <path
            id="Path_260"
            data-name="Path 260"
            d="M23.639,26.5h-2.5a3.75,3.75,0,0,1,0-7.5h8.75a2.5,2.5,0,1,0,0-5h-5V11.5a2.5,2.5,0,1,0-5,0v2.625a8.772,8.772,0,1,0,1.25,17.5h2.5a3.75,3.75,0,0,1,0,7.5h-8.75a2.5,2.5,0,1,0,0,5h5v2.5a2.5,2.5,0,0,0,5,0V44a8.772,8.772,0,1,0-1.25-17.5Z"
            transform="translate(17.604 12)"
          />
          <path
            id="Path_261"
            data-name="Path 261"
            d="M79.042,31.55l-37.5-30a2.5,2.5,0,0,0-3.1,0L24.992,12.3V3.5a2.5,2.5,0,0,0-2.5-2.5h-10a2.5,2.5,0,0,0-2.5,2.5V24.3L.942,31.55a2.491,2.491,0,0,0,3.1,3.9l5.95-4.75V68.5a7.5,7.5,0,0,0,7.5,7.5h45a7.5,7.5,0,0,0,7.5-7.5v-30a2.5,2.5,0,0,0-5,0v30a2.5,2.5,0,0,1-2.5,2.5h-45a2.5,2.5,0,0,1-2.5-2.5V26.7l25-20,35.95,28.75a2.5,2.5,0,1,0,3.1-3.9ZM19.992,6V16.3l-5,4V6Z"
            transform="translate(0 0)"
          />
        </g>
      </svg>
    ),
    title: (
      <>
        Long Term <br /> Rental Friendly
      </>
    ),
    text: "We underwrite as an STR, MTR and LTR just in the event we need to quickly adapt.",
  },
  {
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="75.005"
        height="75"
        viewBox="0 0 75.005 75"
      >
        <g id="Revenue" transform="translate(0.013)">
          <path
            id="Path_281"
            data-name="Path 281"
            d="M37.517,0A37.219,37.219,0,0,0,9.392,12.727V11.719a2.344,2.344,0,0,0-4.687,0V18.75a2.344,2.344,0,0,0,2.344,2.344h7.031a2.344,2.344,0,0,0,0-4.687H12.392A32.812,32.812,0,1,1,5.876,28.758a2.351,2.351,0,1,0-4.5-1.359A37.5,37.5,0,1,0,37.517,0Z"
            transform="translate(0 0)"
          />
          <path
            id="Path_282"
            data-name="Path 282"
            d="M25.062,25.75V16.375h7.031a2.344,2.344,0,1,0,0-4.687H25.062V9.344a2.344,2.344,0,1,0-4.687,0v2.344a9.375,9.375,0,0,0,0,18.75v9.375H13.344a2.344,2.344,0,1,0,0,4.687h7.031v2.344a2.344,2.344,0,1,0,4.687,0V44.5a9.375,9.375,0,1,0,0-18.75Zm-9.375-4.687a4.687,4.687,0,0,1,4.687-4.687V25.75A4.687,4.687,0,0,1,15.687,21.062Zm9.375,18.75V30.437a4.687,4.687,0,0,1,0,9.375Z"
            transform="translate(14.798 9.406)"
          />
        </g>
      </svg>
    ),
    title: "Diversified",
    text: "Business goers, travelers, adventure seekers galore. All are welcome.",
  },
  {
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="65.561"
        height="75"
        viewBox="0 0 65.561 75"
      >
        <g id="Protection" transform="translate(-2 0.002)">
          <path
            id="Path_9006"
            data-name="Path 9006"
            d="M34.265,74.928A41.4,41.4,0,0,1,2,34.328V16.393a2.341,2.341,0,0,1,2.341-2.341A36.643,36.643,0,0,0,32.977.846a2.341,2.341,0,0,1,3.606,0A36.6,36.6,0,0,0,65.219,14.051a2.341,2.341,0,0,1,0,4.683A41.209,41.209,0,0,1,34.78,5.856a41.186,41.186,0,0,1-28.1,12.878V34.375a36.737,36.737,0,0,0,28.1,35.871,36.737,36.737,0,0,0,28.1-35.918v-8.57a2.341,2.341,0,0,1,4.683,0v8.57A41.4,41.4,0,0,1,35.3,74.928,1.92,1.92,0,0,1,34.265,74.928Z"
          />
          <path
            id="Path_9007"
            data-name="Path 9007"
            d="M22.884,24.39H20.543a3.512,3.512,0,1,1,0-7.024h8.2a2.341,2.341,0,0,0,0-4.683H24.055V10.341a2.341,2.341,0,0,0-4.683,0V12.8a8.216,8.216,0,1,0,1.171,16.39h2.341a3.512,3.512,0,0,1,0,7.024h-8.2a2.341,2.341,0,0,0,0,4.683h4.683v2.341a2.341,2.341,0,0,0,4.683,0V40.78a8.216,8.216,0,0,0-1.171-16.39Z"
            transform="translate(13.067 10.734)"
          />
        </g>
      </svg>
    ),
    title: (
      <>
        STR Friendly <br /> States
      </>
    ),
    text: "This one is a must. At least in that major jurisdiction to avoid costly buy and sells.",
  },
  {
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="65.641"
        height="75"
        viewBox="0 0 65.641 75"
      >
        <g id="Investor" transform="translate(-1.998 -0.005)">
          <path
            id="Path_289"
            data-name="Path 289"
            d="M20.032,15.721a2.344,2.344,0,0,1,0-4.688h4.688a2.344,2.344,0,0,0,0-4.688H22.377a2.344,2.344,0,1,0-4.688,0v.422a7.032,7.032,0,0,0,2.344,13.643,2.344,2.344,0,0,1,0,4.688H15.344a2.344,2.344,0,0,0,0,4.688h2.344a2.344,2.344,0,0,0,4.688,0v-.422a7.032,7.032,0,0,0-2.344-13.643Z"
            transform="translate(14.789 5.369)"
          />
          <path
            id="Path_290"
            data-name="Path 290"
            d="M54.91,46.876l-9.845-2.344a23.441,23.441,0,1,0-20.488,0l-9.845,2.344A16.409,16.409,0,0,0,2,63.285a2.344,2.344,0,0,0,4.688,0,11.721,11.721,0,0,1,9.1-11.955L18.9,50.6l1.875,13.01A2.344,2.344,0,0,0,23.1,65.629c.938,0,.375.164,11.721-6.634,11.955,7.173,10.853,6.634,11.721,6.634a2.344,2.344,0,0,0,2.344-2.016L50.761,50.6l3.118.727a11.721,11.721,0,0,1,9.072,11.416v7.572H4.347a2.344,2.344,0,1,0,0,4.688H65.295a2.344,2.344,0,0,0,2.344-2.344V62.746A16.409,16.409,0,0,0,54.91,46.876ZM34.821,52.5l-3.774-4.688,3.774-.938,3.774.867ZM16.068,23.434A18.753,18.753,0,1,1,34.821,42.187,18.753,18.753,0,0,1,16.068,23.434Zm8.861,36.029L23.5,49.548l2.485-.563L31.3,55.619Zm19.785,0-6.376-3.844,5.321-6.634,2.485.563Z"
            transform="translate(0 0)"
          />
        </g>
      </svg>
    ),
    title: "Occupancy",
    text: "Can this property perform “Ok” in the event of lower than expected OCC? Sub 50%.",
  },
  {
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="70.348"
        height="75"
        viewBox="0 0 70.348 75"
      >
        <g id="Diamond" transform="translate(-1)">
          <path
            id="Path_248"
            data-name="Path 248"
            d="M71.348,26.778c0-.868,0-.657-9.685-17.587l-.117-.117a1.782,1.782,0,0,0-.492-.539h0a2.345,2.345,0,0,0-1.43-.516c-50.252,0-47.344-.211-48.259.446s-.211,0-10.06,17.141a3.1,3.1,0,0,0-.281,1.008L1,26.778a3.588,3.588,0,0,0,.211.938c.281.657,33.111,35.666,33.368,35.924a2.345,2.345,0,0,0,2.345.492c.727-.3-.3.68,18.1-19.041A2.345,2.345,0,1,0,51.6,41.9L41.8,52.572l7.785-23.449h14.07l-5.675,6.1A2.355,2.355,0,1,0,61.359,38.5C71.207,27.857,71.348,28.091,71.348,26.778ZM12.936,14.7l7.035,9.731H7.378Zm27.788-1.993H55.074L47.9,22.745ZM43.35,24.433H29L36.174,14.4Zm-18.9-1.688L17.274,12.708H31.625Zm-1.688,6.378,7.785,23.449L8.738,29.123Zm21.878,0L36.174,54.542,27.709,29.123ZM59.412,14.7l5.557,9.731H52.448Z"
            transform="translate(0 10.741)"
          />
          <path
            id="Path_249"
            data-name="Path 249"
            d="M17.345,11.725A2.345,2.345,0,0,1,15,9.38V2.345a2.345,2.345,0,1,1,4.69,0V9.38A2.345,2.345,0,0,1,17.345,11.725Z"
            transform="translate(18.829)"
          />
          <path
            id="Path_250"
            data-name="Path 250"
            d="M14.04,11.385c-1.266,0-1.337-.352-6.355-5.37a2.355,2.355,0,0,1,3.33-3.33l4.69,4.69a2.345,2.345,0,0,1-1.665,4.01Z"
            transform="translate(8.064 2.684)"
          />
          <path
            id="Path_251"
            data-name="Path 251"
            d="M23.353,11.385a2.345,2.345,0,0,1-1.665-4.01l4.69-4.69a2.355,2.355,0,0,1,3.33,3.33C24.6,11.127,24.619,11.385,23.353,11.385Z"
            transform="translate(26.891 2.684)"
          />
        </g>
      </svg>
    ),
    title: "Unique",
    text: "How can we make this “pop” off the screen? Guests take 7 minutes before picking a spot.",
  },
];

const strategySliderItems = [
  {
    img: require("assets/images/svg/Chart-Bar.svg"),
    title: (
      <>
        20%+ <br /> Annual ROI
      </>
    ),
    text: "It should hit at least 20% ROI as an average over the first 5 years (cash + equity + tax benefits). Typically, by year 3.",
  },
  {
    img: require("assets/images/svg/Chart.svg"),
    title: (
      <>
        Low <br /> Seasonality
      </>
    ),
    text: "We avoid markets with 50% or more swings of seasonality. A little bit of seasonality is normal.",
  },
  {
    img: require("assets/images/svg/Location.svg"),
    title: (
      <>
        Tax Friendly <br /> States
      </>
    ),
    text: "It’s what you keep. Not what you make. Live anywhere but invest where it makes sense.",
  },
  {
    img: require("assets/images/svg/Barcode.svg"),
    title: (
      <>
        Underwriting <br /> 5 Ways
      </>
    ),
    text: "Us. Lender. Property Management. Us Again. Lender Again. 5 points of failure. Check.",
  },
  {
    img: require("assets/images/svg/Coins.svg"),
    title: (
      <>
        Cost <br /> Segregation
      </>
    ),
    text: "Who wants to pay taxes when you can be smart about it? Our properties see 25-35% write offs.",
  },
  {
    img: require("assets/images/svg/Charity.svg"),
    title: (
      <>
        Accelerated <br /> Depreciation{" "}
      </>
    ),
    text: "Yup, more tax savings. We want tax free cash flow after all. So, we look for those opportunities.",
  },
  {
    img: require("assets/images/svg/Ingot.svg"),
    title: (
      <>
        Multi Platform <br /> Exposure
      </>
    ),
    text: "Can we list this type of property across multiple channels? We want this to be yes.",
  },
];

const StrategyCard = ({ svg, title, text }) => {
  return (
    <div className="strategyCard__holder">
      <div className="blackCard blackCard--hover blackBg commonBoxshadow">
        <div className="blackCard__inner d-flex flex-column">
          <div className="blackCard__img">{svg}</div>
          <div className="blackCard__hdng">
            <h3 className="blueClr">{title}</h3>
          </div>
          <div className="blackCard__cntnt">
            <p>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const StrategySliderItem = ({ img, title, text }) => {
  return (
    <div className="item">
      <div className="strategySliderBox commonBoxshadow">
        <div className="strategySliderBox__img">
          <Img src={img} />
        </div>
        <div className="strategySliderBox__cntnt">
          <h3>{title}</h3>
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default ({ title, className = "" }) => {
  useLayoutEffect(() => {
    if (window.strategySlider) {
      window.strategySlider();
    }
  }, []);
  return (
    <Template title={title} className={className}>
      <Banner
        className="strategypageBanner"
        banner={require("assets/images/strategyBanner-img.jpg")}
        title={<>Strategy</>}
      >
        <Link
          to="/request-invite"
          className="commonBtn blackBg d-flex align-items-center justify-content-center"
        >
          <span>Request invite</span>
          <Img src={require("assets/images/svg/arrow.svg")} />
        </Link>
        <div className="dataArea">
          <div className="dataArea__box">
            <div className="dataCount textGradient">
              <span className="counter" data-to="40"></span>%
            </div>
            <div className="dataCntnt">
              <p>
                Lower costs to operate <br /> because of economies <br /> of
                scale + tech{" "}
              </p>
            </div>
          </div>
          <div className="dataArea__box boxPosition">
            <div className="dataCount textGradient">
              <span className="counter" data-to="20"></span>%
            </div>
            <div className="dataCntnt">
              <p>
                Higher profits with <br /> functional design + <br /> automation{" "}
              </p>
            </div>
          </div>
        </div>
      </Banner>
      <>
        <section className="wideSection wideCommonstyle widestrategy">
          <div className="container-fluid">
            <div className="wideSection__row d-flex align-items-center justify-content-between">
              <div
                className="wideSection__img roundedCorner__right commonBoxshadow "
                data-emergence="visible"
              >
                <Img src={require("assets/images/strategy-img-1.jpg")} />
              </div>
              <div className="wideSection__content">
                <div
                  className="commonHeading blueClr animated fdeIn__Up"
                  data-emergence="visible"
                >
                  Our strategy is refreshingly simple and boring.
                </div>
                <h3 className="regular">
                  Focus on the experience, reviews, keeping expenses low,
                  automate what we can and build in systems and processes for
                  repetitive, mundane things.
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section className="strategycSection strategyIntersection">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="strategycSection__leftcntnt">
                  <div
                    className="commonHeading animated"
                    data-emergence="visible"
                  >
                    Focused, <br />
                    <span className="blueClr">Strategic</span>, Yet <br />{" "}
                    Simple.
                  </div>
                  <div className="strategycBox">
                    <div className="strategycBox__img">
                      <Img src={require("assets/images/svg/Annual.svg")} />
                    </div>
                    <div className="strategycBox__cntnt">
                      <h3>
                        $ <span className="counter" data-to="200"></span>+ ADRs{" "}
                      </h3>
                      <p>
                        Can this property fetch at least $200 bucks a night? If
                        so, we’re on it like hotcakes. Q1 2022, we’re trending
                        at $300+.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="strategycSection__rgtcntnt">
                  <div className="strategycBox">
                    <div className="strategycBox__img">
                      <Img src={require("assets/images/svg/Statistic.svg")} />
                    </div>
                    <div className="strategycBox__cntnt">
                      <h3>
                        Under $ <span className="counter" data-to="750"></span>k
                        homes{" "}
                      </h3>
                      <p>
                        We prioritize staying sub the $750k range as to protect
                        against downside risk. With some outliers of course.
                      </p>
                    </div>
                  </div>
                  <div className="strategycBox">
                    <div className="strategycBox__img">
                      <Img src={require("assets/images/svg/Cash-flow.svg")} />
                    </div>
                    <div className="strategycBox__cntnt">
                      <h3>
                        <span className="counter" data-to="10"></span>%+
                        Cash-on-Cash
                      </h3>
                      <p>
                        During underwriting, the property must produce at least
                        10% CoC and maintain an upward projection.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="strategySliderHolder">
            <div className="container">
              <div className="strategySlider__head">
                <div className="slides-numbers" id="strategySliderCounter">
                  <span className="active">01</span> -{" "}
                  <span className="total">{strategySliderItems.length}</span>
                </div>
              </div>
              <div className="strategySlider">
                {strategySliderItems.map((strategy, id) => (
                  <StrategySliderItem key={id} {...strategy} />
                ))}
              </div>
            </div>
          </div>
          <div className="strategyCard_wrap">
            <div className="container">
              <div className="strategyCard">
                {strategyCards.map((strategy, id) => (
                  <StrategyCard key={id} {...strategy} />
                ))}
              </div>
            </div>
          </div>
        </section>
      </>
      <Review className="clientSection" />
      <GoRequest img={require(`assets/images/strategyBtm-img.jpg`)} />
    </Template>
  );
};
